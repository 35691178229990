import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Url, Permission } from '../service/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Share } from '@capacitor/share';
import html2canvas from 'html2canvas';
import { HttpClient } from '@angular/common/http';
import { event } from 'jquery';
import { ChangeViewLoans } from '../list-client/list-client.component';
import { Observable } from 'rxjs';
import { CapacitorIntent  } from 'capacitor-intent';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  @ViewChild('hiddenContainer') hiddenContainer!: ElementRef<HTMLDivElement>;
  selectedDates: Date;
  selectedDates_info: any;
  value_cuota: string = '';
  value_cuota2: string = '';
  value_cuota_rela: string = '';
  optionCount: any;
  cuotas_pendiente: string = '';
  pendiente: string = '';
  detail_id: string = '';
  saldo: string = '';
  payment_paymeny?: boolean;
  loan_id: any = 0;
  cuotas_loan: any = 0;
  formattedFeePayment: any = 0;
  description_payment: any;
  permissions: any = {};
  options_seelec: any;
  minDateValue: Date;
  isAdmin = false;
  lat: any = '';
  long: any = '';
  htmls: string = '';

  fecha_ven: any = "";
  cuota_pay:any = "";
  saldo_recibe:any = "";
  value_cuota_r:any = "";
  type_frecuncy_r: any = "";
  mora_r: any = "";


  fullNames: string = '';

  constructor(
    public url: Url,
    private parames: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
    this.selectedDates = new Date();
    this.selectedDates_info = `${String(this.selectedDates.getDate()).padStart(
      2,
      '0'
    )}/${String(this.selectedDates.getMonth() + 1).padStart(
      2,
      '0'
    )}/${this.selectedDates.getFullYear()}`;
    this.minDateValue = new Date();
  }
  ngOnInit() {
    //this.getLocation();
    let rol = this.getCookie('_rol');
    if (rol == 'SuperUsuario') {
      this.isAdmin = true;
    }
    this.permissions = Permission.ElementPermissionObjects();
    this.parames.params.subscribe(async (params) => {
      let info = await this.get_info();
      const feePayment = info.fee_payment;
      this.value_cuota = (
        parseFloat(feePayment) - parseFloat(this.saldo)
      ).toFixed(2);
      this.value_cuota2 = feePayment;
      this.value_cuota_rela = feePayment;
      const optionIndexes = Array.from(
        { length: parseFloat(this.cuotas_loan) },
        (_, index) => index + 1
      );
      this.optionCount = optionIndexes;
      let description = ((
        document.getElementById('details') as HTMLInputElement
      ).value = this.description_payment ? this.description_payment : '');
    });
  }

  fechaIndi() {
    let input_date = document.getElementById(
      'date_payment'
    ) as HTMLInputElement;
    input_date.click();
  }
  onDateChange(newDate: any) {
    this.selectedDates_info = `${String(this.selectedDates.getDate()).padStart(
      2,
      '0'
    )}/${String(this.selectedDates.getMonth() + 1).padStart(
      2,
      '0'
    )}/${this.selectedDates.getFullYear()}`;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
        },
        (error) => {
          console.error('Error al obtener la ubicación:', error);
        }
      );
    } else {
      console.error('La geolocalización no es compatible con este navegador.');
    }
  }

  datos: any[] = [];
  async get_info(): Promise<any> {
    const id = this.loan_id;
    const token = this.getCookie('_token');
    const response = await fetch(this.url.Ms1 + 'prestamo/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      },
    });
    if (response.ok) {
      const jsonResponse = await response.json();
      this.datos = jsonResponse;
      this.cuotas_pendiente = jsonResponse.loan_details_id.missing_installments;
      this.pendiente = jsonResponse.loan_details_id.outstanding_balance;
      this.detail_id = jsonResponse.loan_details_id.id;
      const numericValue = parseFloat(
        jsonResponse.loan_details_id.positive_balance
      );
      this.saldo = numericValue.toFixed(2);
      return this.datos;
    }
  }

  loadHtml(filePath: string): Observable<string> {
    return this.http.get(filePath, { responseType: 'text' });
  }

  getCookie(name: string) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  async payment_laste(id:any){
    const token = this.getCookie('_token');
    const response = await fetch(this.url.Ms1 + "pago_loan/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    if (response.ok){
      const result = await response.json()
      let total_pay = 0;
      result.forEach((item: any) => {
        total_pay += parseFloat(item.fee_payment) || 0; 
      });

      const latestPayment = result[result.length - 1];
      const result_r = {
          date: latestPayment.date,
          value_pay: latestPayment.fee_payment,
          pay_total: String(total_pay)
      };
      return result_r
    }
    else{
      const result_r = {
        date: "",
        value_pay: "0",
        pay_total: ""
    };
    return result_r
    }

  }

  formatDate(dateString: string): string {
    const months = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const date = new Date(dateString);
    const day = date.getDate() + 1;
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

formatPaymentInfo(payment: { date: string, value_pay: string, pay_total: string }): string {
  if (payment.date == ""){
    const formattedAmount = `$${payment.value_pay}`;
    if (payment.value_pay == '0'){
      return ""
    }
    return `${formattedAmount}`;
  }
  else{
    const formattedDate = this.formatDate(payment.date);
    const formattedAmount = `$${payment.value_pay}`;
    return `${formattedDate} / ${formattedAmount}`;
  }
}


  async PaymentsLoan() {
    var pagado_finsih = false;
    const button_guardar = document.getElementById(
      'enviar_form'
    ) as HTMLButtonElement;
    let ultimo_pay = await this.payment_laste(this.loan_id)
    button_guardar.style.display = 'none';
    const token = this.getCookie('_token');
    const chas_flow_id = this.getCookie('_cash');
    const id = this.loan_id;
    const sucursal = this.getCookie('_branch');
    const date_payment = (
      document.querySelector('#date_payment') as HTMLSelectElement
    ).value;
    const date_payment2 = (
      document.querySelector('#date_payment') as HTMLSelectElement
    ).value;

    let [dia, mes, año] = date_payment2.split('/').map(Number);
    let fecha = new Date(año, mes - 1, dia);

    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let period = hours >= 12 ? 'PM' : 'AM';
    let minutesString  = minutes < 10 ? '0' + minutes : minutes;
    let timeString = `${hours}:${minutesString} ${period}`;

    const values = (document.querySelector('#value_cuota') as HTMLSelectElement)
      .value;
    const type_payments = (
      document.querySelector('#type_payments') as HTMLSelectElement
    ).value;
    if (type_payments == 'True') {
      this.payment_paymeny = true;
    } else {
      this.payment_paymeny = false;
    }
    const cuotas = '1';

    let valor_cuota = '';
    const cuotas_pendiente = this.cuotas_pendiente;
    const pendiente = parseFloat(this.pendiente) - parseFloat(values);
    const detail_id = parseFloat(this.detail_id);
    let saldos = parseFloat(this.saldo);
    const total_pagos = parseFloat(values);
    let state = 'Activo';
    function DefinirSaldo(Cuota: number, Saldo: number, Pago: number) {
      Pago = Pago + Saldo;
      Saldo = Pago % Cuota;
      return parseFloat(Saldo.toFixed(2));
    }

    let saldoCalc = DefinirSaldo(
      parseFloat(this.value_cuota_rela),
      saldos,
      parseFloat(values)
    );
    const data: {
      date: string;
      fee_payment: string;
      totla_value: number;
      number_installments: number;
      loans_id: any;
      chas_flow_id: string | null;
      missing_installments: number;
      outstanding_balance: number;
      detail_id: number;
      positive_balance: number;
      state?: string; // Declarar 'state' como una propiedad opcional
      loan_cancel?: boolean; // Declarar 'state' como una propiedad opcional
      is_pix: boolean;
      sucursal_id: any;
      latitude: any;
      longitude: any;
    } = {
      date: this.formatearFecha(fecha, ''),
      fee_payment: valor_cuota,
      totla_value: parseFloat(parseFloat(values).toFixed(2)),
      number_installments: 0,
      loans_id: id,
      chas_flow_id: chas_flow_id,
      missing_installments: 0,
      outstanding_balance: parseFloat(pendiente.toFixed(2)),
      detail_id: detail_id,
      positive_balance: saldoCalc,
      is_pix: this.payment_paymeny,
      sucursal_id: sucursal,
      latitude: this.lat,
      longitude: this.long,
    };

    if (!cuotas || !values || !date_payment || !type_payments) {
      button_guardar.style.display = 'block';
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true, // Mostrar el botón "OK"
        confirmButtonText: 'OK', // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false, // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }
    const allowedChars = /^\d+(\.\d{1,2})?$/;
    if (!allowedChars.test(values)) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Solo valores enteros en el valor',
        showConfirmButton: true, // Mostrar el botón "OK"
        confirmButtonText: 'OK', // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false, // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }

    if (!isNaN(total_pagos)) {
      console.log('El valor es numérico');
    } else if (typeof total_pagos === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Solo valores enteros en el valor',
        showConfirmButton: true, // Mostrar el botón "OK"
        confirmButtonText: 'OK', // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false, // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }

    if (total_pagos == parseFloat(this.pendiente)) {
      Swal.fire({
        icon: 'info',
        title: 'Confirmar',
        text: 'El valor pagado cancela la venta ¿esta seguro?',
        confirmButtonText: 'Pagar',
        confirmButtonColor: '#0e658e',
        allowOutsideClick: false,
        cancelButtonColor: '#d33',
        showCancelButton: true,
        allowEscapeKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          data.state = 'Inactivo';
          data.loan_cancel = true;
          try {
            const response = await fetch(this.url.Ms1 + 'pago_loan', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              },
              body: JSON.stringify(data),
            });
            if (response.ok) {
              let pendit = String(parseFloat(this.saldo_recibe) - parseFloat(values))
              let total_loan = (parseFloat(ultimo_pay.pay_total) + parseFloat(values))
              let cuota_final = Math.floor((total_loan / this.value_cuota_r))
              let formattedPaymentInfo = this.formatPaymentInfo(ultimo_pay);

              this.loadHtml('assets/prueba.html').subscribe((html) => {
                const updatedHtml = html
                  .replace('{{value}}', values)
                  .replace('{{date_r}}',this.formatearFecha(fecha, ''))
                  .replace('{{hora}}',timeString)
                  .replace('{{name}}', this.fullNames)
                  .replace('{{fecha_ven}}',this.formatDate(this.fecha_ven))
                  .replace('{{frecuency}}', this.type_frecuncy_r)
                  .replace('{{cuota_pay}}', this.cuota_pay.replace('{{cambio}}',cuota_final ))
                  .replace('{{pendiente}}', pendit)
                  .replace('{{cuota_value}}', this.value_cuota_r)
                  .replace('{{mora}}', this.mora_r)
                  .replace('{{type}}', this.payment_paymeny === true ? "Taransefer" : "Efectivo")
                  .replace('{{last_pay}}', formattedPaymentInfo);
                this.htmls = updatedHtml;
                this.renderHtmlToImage();
              });
            }else{
              button_guardar.style.display = 'block';
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error!',
                    text: 'No se pude crear el pago',
                    showConfirmButton: true,
                  });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          button_guardar.style.display = 'inline-block';
        }
      });
    }

    if (total_pagos < parseFloat(this.pendiente)) {
      try {
        const response = await fetch(this.url.Ms1 + 'pago_loan', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          let pendit = String(parseFloat(this.saldo_recibe) - parseFloat(values))
          let payTotal = parseFloat(ultimo_pay?.pay_total) || 0;
          let valuesParsed = parseFloat(values) || 0;
          let total_loan = payTotal + valuesParsed;
          let cuota_final = Math.floor(total_loan / (this.value_cuota_r || 1));
          let formattedPaymentInfo = this.formatPaymentInfo(ultimo_pay);
          this.loadHtml('assets/prueba.html').subscribe((html) => {
            const updatedHtml = html
              .replace('{{value}}', values)
              .replace('{{date_r}}',this.formatearFecha(fecha, ''))
              .replace('{{hora}}',timeString)
              .replace('{{name}}', this.fullNames)
              .replace('{{fecha_ven}}',this.formatDate(this.fecha_ven))
              .replace('{{frecuency}}', this.type_frecuncy_r)
              .replace('{{cuota_pay}}', this.cuota_pay.replace('{{cambio}}',cuota_final ))
              .replace('{{pendiente}}', pendit)
              .replace('{{cuota_value}}', this.value_cuota_r)
              .replace('{{mora}}', this.mora_r)
              .replace('{{type}}', this.payment_paymeny === true ? "Transferencia" : "Efectivo")
              .replace('{{last_pay}}', formattedPaymentInfo);
            this.htmls = updatedHtml;
            this.renderHtmlToImage();
          });
         
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (total_pagos > parseFloat(this.pendiente)) {
      button_guardar.style.display = 'block';
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'El valor a pagar es mayor a la venta',
        showConfirmButton: true, // Mostrar el botón "OK"
        confirmButtonText: 'OK', // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false, // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }
  }
  isCapacitor(): boolean {
    return (window as any).Capacitor !== undefined;
  }

  extractBase64Data(dataURL: string): string {
    return dataURL.split(',')[1]; // Elimina el encabezado data:image/jpeg;base64,
  }

  async saveFileToExternalStorage(filePath: string, base64Image: string): Promise<string> {
    const folderPath = 'Pacific/Media';
    try {
      await Filesystem.stat({
        path: folderPath,
        directory: Directory.ExternalStorage,
      });
    } catch (error) {
      await Filesystem.mkdir({
        path: folderPath,
        directory: Directory.ExternalStorage,
        recursive: true,
      });
    }
    await Filesystem.writeFile({
      path: `Pacific/Media/${filePath}`,
      data: base64Image,
      directory: Directory.ExternalStorage
    });
    return `file:///storage/emulated/0/Pacific/Media/${filePath}`;
  }


  async renderHtmlToImage() {
    if (document.getElementById("infor_real")) {
      let contes = (document.getElementById("infor_real") as HTMLElement)
      contes.innerHTML = this.htmls;
      let elemneto = document.getElementById('principal_send') as HTMLElement;
      try{
        const canvas = await html2canvas(elemneto);
        canvas.id = "img_rec"
        document.body.appendChild(canvas);
        const image = canvas.toDataURL('image/jpeg');
        const imageBlob = this.dataURLtoBlob(image);
        const fileName = 'recibo.jpg';
        document.getElementById("img_rec")?.remove()
        if (!this.isCapacitor()) {
          try{
            const blob = this.extractBase64Data(image)
            const fileUri = await this.saveFileToExternalStorage(fileName, blob);
            // const intent = {
            //   action: 'android.intent.action.SEND',
            //   url: fileUri,
            //   type: 'image/jpeg',
            //   package: 'com.whatsapp'
            // };
            // await CapacitorIntent.startActivity(intent);
            await Share.share({
              title: 'Recibo de pago',
              url: fileUri,
            });
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Enviado!',
              text: 'Pago Creado',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: '#0e658e',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await ChangeViewLoans(this.options_seelec);
              }
            });
          }
          catch (error) {
            alert("Error al compartir: " + (error as Error).message);
          }          
        }else if (navigator.share) {
          const files_up = new File([imageBlob], 'recibo.png', { type: 'image/png' });
          navigator
            .share({
              title: 'Recibo de pago',
              files: [files_up],
            })
            .then(data  => {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Enviado!',
                  text: 'Pago Creado',
                  showConfirmButton: true, // Mostrar el botón "OK"
                  confirmButtonText: 'OK', // Texto del botón "OK"
                  allowOutsideClick: false, // Evitar el clic fuera del cuadro
                  allowEscapeKey: false, // Evitar la tecla "esc"
                  confirmButtonColor: '#0e658e',
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await ChangeViewLoans(this.options_seelec);
                  }
                });
              }
            )
            .catch(error=> {
              alert("error")
            });
        } else {
          alert("la api no esta disponible")
         
        }

      }
      catch (error) {
        console.error('Error al procesar la imagen o compartir:', error);
        alert("Error al compartir: " + error);
      }


      
    }
  }

  dataURLtoBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  formatearFecha(fecha: any, type: any) {
    let dia = fecha.getDate();
    if (type == 'tarde') {
      dia = fecha.getDate();
    }
    if (type == 'pago') {
      dia = fecha.getDate() + 1;
    }
    let mes = fecha.getMonth() + 1; // Sumamos 1 al mes ya que en JavaScript los meses van de 0 a 11
    let año = fecha.getFullYear();
    dia = dia < 10 ? '0' + dia : dia;
    mes = mes < 10 ? '0' + mes : mes;
    return `${dia}/${mes}/${año}`;
  }

  async NotPay() {
    Swal.fire({
      icon: 'warning',
      title: 'Poner no pago',
      text: '¿Seguro quiere poner no pago?',
      showConfirmButton: true, // Mostrar el botón "OK"
      confirmButtonText: 'Guardar', // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false, // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let fechas = new Date();
        const token = this.getCookie('_token');
        const id = parseInt(this.loan_id);
        let fecha = this.formatearFecha(fechas, 'pago');
        let detalle = 'No pago';

        let data = {
          date: fecha,
          detalle: detalle,
          loans_id: id,
        };
        const response = await fetch(this.url.Ms1 + 'alert/payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'ngrok-skip-browser-warning': '69420',
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'No pago creado',
            showConfirmButton: true, // Mostrar el botón "OK"
            confirmButtonText: 'OK', // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false, // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then(async (result) => {
            var conteActivos = document.getElementById(
              'content_activos-' + id
            ) as HTMLInputElement;
            conteActivos.style.display = 'none';
          });
        }
      }
    });
  }

  sendPayTo() {
    Swal.fire({
      icon: 'info',
      title: 'Programar pago',
      text: '¿Seguro quiere poner pago mas tarde?',
      showConfirmButton: true, // Mostrar el botón "OK"
      confirmButtonText: 'Guardar', // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false, // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let fechas = new Date();
        const token = this.getCookie('_token');
        const id = parseInt(this.loan_id);
        let fecha = this.formatearFecha(fechas, 'tarde');
        let detalle = 'Pago mas tarde';

        let data = {
          date: fecha,
          detalle: detalle,
          loans_id: id,
        };
        const response = await fetch(this.url.Ms1 + 'alert/payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'ngrok-skip-browser-warning': '69420',
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Cliente agendado para mas tarde',
            showConfirmButton: true, // Mostrar el botón "OK"
            confirmButtonText: 'OK', // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false, // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          });
        }
      }
    });
  }
}
