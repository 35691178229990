<div class="card-body data-content">
  <h2 *ngIf="esEdicion">Actualizar Sucursal</h2>
  <form autocomplete="off" class="form" name="miFormularioSucursal" [formGroup]="miFormularioSucursal" (ngSubmit)="enviarFormularioSucursal()">
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Nombre Sucursal</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form form-county" maxlength="25" type="text" id="nombre_sucursal" name="nombre_sucursal"  (blur)="convertToUppercase('nombre_sucursal')"  placeholder="Nombre"  formControlName="nombre_sucursal">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Telefono</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form form-county" id="numero" name="numero"  type="number" maxlength="11" placeholder="Telefono" inputmode="numeric" formControlName="numero" >
      </div>
    </div>


    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Pais</label>
      <div class="col-lg-8 col-12 inputs">
        <select class="form-control input-form selectpicker" name="pais" id="pais" formControlName="pais">
          <optgroup label="Suramérica">
            <option value="Argentina">Argentina</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Brasil">Brasil</option>
            <option value="Chile">Chile</option>
            <option value="Colombia">Colombia</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Perú">Perú</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Uruguay">Uruguay</option>
          </optgroup>
          <optgroup label="Centroamérica">
            <option value="Guatemala">Guatemala</option>
            <option value="Honduras">Honduras</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Panamá">Panamá</option>
            <option value="México">México</option>
          </optgroup>
        </select>
      </div>
    </div>


    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Moneda</label>
      <div class="col-lg-8 col-12 inputs">
        <input class="form-control input-form form-county" type="text" id="moneda" name="moneda"  (blur)="convertToUppercase('moneda')"  placeholder="Moneda"  formControlName="moneda">
      </div>
    </div>
    
    
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label" >Hora inicial:</label>
      <div class="col-lg-8 col-12 inputs">
        <select class="form-control input-form" id="start_time" name="start_time" formControlName="tiempo_laboral" >
          <option value="">--Elige--</option>
          <option value="08:00">08:00 AM</option>
          <option value="09:00">09:00 AM</option>
          <option value="10:00">10:00 AM</option>
          <option value="11:00">11:00 AM</option>
          <option value="12:00">12:00 PM</option>
          <option value="13:00">01:00 PM</option>
          <option value="14:00">02:00 PM</option>
          <option value="15:00">03:00 PM</option>
          <option value="16:00">04:00 PM</option>
          <option value="17:00">05:00 PM</option>
      </select>
       
      </div>
    </div>
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label" >Hora Final del dia:</label>
      <div class="col-lg-8 col-12 inputs">
        <select class="form-control input-form" id="end_time" name="end_time" formControlName="tiempo_laboral_final">
          <option value="">--Elige--</option>
          <option value="16:00">04:00 PM</option>
          <option value="17:00">05:00 PM</option>
          <option value="18:00">06:00 PM</option>
          <option value="19:00">07:00 PM</option>
          <option value="20:00">08:00 PM</option>
          <option value="21:00">09:00 PM</option>
          <option value="22:00">10:00 PM</option>
          <option value="23:00">11:00 PM</option>
          <option value="00:00">12:00 AM</option>
          <option value="01:00">01:00 AM</option>
      </select>

      </div>
    </div>
  <!--
    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label">Montos:</label>
      <div class="container-select">
        <div class="select-btn" #selectBtn>
          <span class="btn-text" #btnText>Montos</span>
        </div>

        <ul class="list-items" #itemsContainer>
          <li class="item-monto" *ngFor="let item of itemsList"  #itemMonto>
            <span class="checkbox">
              <i class="fa-solid fa-check check-icon"></i>
            </span>
            <span class="item-text" [attr.data-value]="item.id">{{ item.amount }}</span>
          </li>
        </ul>
      
      </div>
    </div>
    -->

    <div class="form-group row">
      <label class="col-lg-3 col-form-label form-control-label"></label>
      <div class="col-lg-8 col-12 inputs">
        <input *ngIf="!esEdicion" class="btn btn-primary subtmit-bt" id="enviar-sucursal" type="submit" value="Guardar">
        <input *ngIf="esEdicion" class="btn btn-primary subtmit-bt"  (click)="actualizarFormularioSucursal(miFormularioSucursal.value)"  value="Actualizar">
       
      </div>
    </div>
  </form>
</div>