import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-routing-machine';

@Component({
  selector: 'app-mapa-loan',
  templateUrl: './mapa-loan.component.html',
  styleUrls: ['./mapa-loan.component.css'],
})
export class MapaLoanComponent  implements OnInit {

  pagos:any = []
  lat:any = "";
  lon:any = "";
  private map!: L.Map;
  constructor() { }

  ngOnInit() {
    this.initMap();
  }

  private initMap(): void {
    const map = L.map('map').setView([4.816896, -75.7006336], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© Pacific'
    }).addTo(map);

    const iconDefault = L.icon({
      iconUrl: 'assets/images/marker-icon.png',  // Ruta a los iconos en assets
      shadowUrl: 'assets/images/marker-shadow.png',
      iconSize: [25, 41],  // Tamaño del ícono
      iconAnchor: [12, 41], // Punto de anclaje
      popupAnchor: [1, -34], // Punto desde el cual se alinea el popup
      shadowSize: [41, 41]  // Tamaño de la sombra
    });

    if (this.pagos.gps_loans_id !== null){
      this.lat = parseFloat(this.pagos.gps_loans_id.latitude);
      this.lon = parseFloat(this.pagos.gps_loans_id.longitude);
    }

    L.marker([this.lat, this.lon], {icon: iconDefault}).addTo(map)
      .bindPopup(`<b>Prestamo valor </b> $${this.pagos.value}<br>Fecha:${new Date(this.pagos.creation_date).toLocaleDateString()}<br>Esta es la primera ubicación.`)
      .openPopup();
  }

}
