import {
  Component,
  ElementRef,
  ViewChild,
}
from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';
import { Location } from '@angular/common';
import { HtmlParser } from '@angular/compiler';

@Component({
  selector: 'app-form-nomina',
  templateUrl: './form-nomina.component.html',
  styleUrls: ['./form-nomina.component.css']
})
export class FormNominaComponent {
  selectedConceptId: number[] = [];
  IsMeta = false;
  datos_wages: any[] = [];
  listConcept = false;
  Isbono = false;
  bonus_metas: any[] = [];
  frecuenciaSeleccionada: string = '';
  valoresNumero: any = {
    semanal: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
    quincenal: [],
    mensual: []
  };
  valorNumeroSeleccionado: any = 0;
  valorNumeroSeleccionadoBono: string[] = [];
  valueBonos: any[] = [];
  datos: any[] = [];
  miFormularioNomina: FormGroup = this.formBuilder.group({
    'frecuencia': ['', Validators.required],
    'numero': ['', Validators.required ],
    'details': ['', Validators.required],
    'values_wages': ['', Validators.required],
    'bonos_list': ['', Validators.required],
    'bono_select': ['', Validators.required],
  });



  @ViewChild('selectBtn2') selectBtnRef2!: ElementRef;
  @ViewChild('btnText2') btnTextRef2!: ElementRef;
  @ViewChild('itemsContainer2') itemsContainerRef2!: ElementRef;


  router: any;
  constructor(private formBuilder: FormBuilder, public url: Url,private location: Location) {
    for (let i = 1; i <= 30; i++) {
      this.valoresNumero.mensual.push(i);
    }
    for (let i = 1; i <= 15; i++) {
      this.valoresNumero.quincenal.push(i);
    }
  }

  addClickEventListener2() {
   const selectBtn2 = this.selectBtnRef2.nativeElement;
    selectBtn2.addEventListener('click', () => {
      selectBtn2.classList.toggle('open');
    });
  }

  click_checks_Total(event: Event) {
    const element = event.currentTarget as HTMLElement;
    element.classList.toggle('checked2');
    let checked = document.querySelectorAll(".monto-sele");
    var btnText = document.querySelector(".btn-text2") as HTMLElement;
    let selectedTexts: string[] = [];
    const isAllChecked = element.classList.contains('checked2');
  
    checked.forEach((item: Element) => {
      item.classList.toggle('checked2', isAllChecked);
      if (isAllChecked) {
        selectedTexts.push(item.textContent || "");
      }
    });
    // Asignamos los textos concatenados al botón
    if (selectedTexts.length > 0) {
      btnText.innerText = selectedTexts.join(", ");
    } else {
      btnText.innerText = "Seleccionar bonos";
    }
  
  }
  
  
  ngAfterViewInit() {
    this.addClickEventListener2();
  }

  ngOnInit() {
    this.listGoals();
    const branch = this.getCookie("_branch")
    const role = this.getCookie("_rol")
    if(role != "SuperUsuario"){
      if(branch){
        this.sucursal_id(branch)
      }
    }
    else{
      this.getSucursal();
    }
  }

  metaIsd(){
    const meta = (document.querySelector('#meta') as HTMLInputElement).value;
    if (meta == "si"){
      this.IsMeta = true;
      this.add_bonus()
      
    }
    else{
      this.IsMeta = false;
    }
  }
  BonosIsd(){
    const meta = (document.querySelector('#bono_select') as HTMLInputElement).value;
    if (meta == "si"){
      document.getElementById("conten_bonos")?.classList.remove("hidden-bono")
      this.addClickEventListener2();
    }
    else{
      document.getElementById("conten_bonos")?.classList.add("hidden-bono")
      this.Isbono = false;
    }
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  addInput(container: any) {
    const items = document.querySelectorAll('[id^=porcentaje_]');
    let counter = items.length;
  
    return function() {
      counter++;
      const newDiv = document.createElement('div');
      newDiv.innerHTML = `
      <div style="display:flex;">
      <div style="margin-right: 2%;">
        <b>Porcentaje ${counter}</b>
        <input type="number"  inputmode="numeric" id="porcentaje_${counter}" class="swal2-input" placeholder="Porcentaje">
      </div>
      <div style="margin-left:2% ;">
        <b>Valor ${counter}</b>
        <input type="number"  inputmode="numeric" id="values_id_${counter}" class="swal2-input" placeholder="Valor">
      </div>
      </div>
      `;
      container.appendChild(newDiv);
    };
  }

  add_bonus(){

    if (this.bonus_metas && this.bonus_metas.length > 0) {
      const existingValuesHtml = this.bonus_metas.map((bonus, index) => `
      <div style="display:flex;">
        <div style="margin-right: 2%;">
          <b>Porcentaje ${index + 1}</b>
          <input type="number"  inputmode="numeric" class="swal2-input" id="porcentaje_${index +1}" value="${bonus.percentage}">
        </div>
        <div style="margin-left: 2%;">
          <b>Valor ${index + 1}</b>
          <input type="number"  inputmode="numeric" class="swal2-input" id="values_id_${index + 1}"value="${bonus.value}">
        </div>
          <button style="margin-left: 10px; background-color: red; color: white;" id="remove_${index}">x</button>
      </div>
    `).join('');

    Swal.fire({
      title: 'Agregar comisiones',
      html: `
        <div id="inputsContainer">
          ${existingValuesHtml}
          <a id="addButton" style="color: #2196F3;display: flex;text-decoration: underline;">Agregar Campos +</a>
        </div>
      `,
      confirmButtonColor: '#0e658e',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      onBeforeOpen: (modalElement) => {
        const addButton = modalElement.querySelector('#addButton') as HTMLButtonElement;
        const container = modalElement.querySelector('#inputsContainer');
        const addNewInput = this.addInput(container);
        addButton.addEventListener('click', addNewInput);
        this.bonus_metas.forEach((bonus, index) => {
          const removeButton = modalElement.querySelector(`#remove_${index}`) as HTMLButtonElement;
          removeButton.addEventListener('click', () => {
            const field = document.getElementById(`field_${index}`);
            field?.remove();
          });
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const existingValues = this.bonus_metas || [];
        const inputs = document.querySelectorAll('[id^=porcentaje_]');
        const newValues: { percentage: string; value: string }[] = [];
        inputs.forEach(input => {
          const idParts = input.id.split('_');
          const index = idParts[idParts.length - 1];
          const percentage = (document.getElementById(`porcentaje_${index}`) as HTMLInputElement).value;
          const value = (document.getElementById(`values_id_${index}`) as HTMLInputElement).value;
          newValues.push({ percentage, value });
          if(percentage == "" || value == ""){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Comision creada',
              text: "Los campos vacios no se guardarán",
              showConfirmButton: false,
              timer: 3600
            });
            return;
          }
        });
        this.bonus_metas = [...newValues];
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Comision creada',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        })

      }
    });
    }
    else{
      Swal.fire({
        title: 'Agregar comisiones',
        html: `
        <div id="inputsContainer">
          <div  style="display:flex;">
            <div style="margin-right: 2%;">
              <b>Porcentaje 1</b>
              <input type="number"  inputmode="numeric" id="porcentaje_1" class="swal2-input" placeholder="Porcentaje">
            </div>
            <div style="margin-left: 2%;">
              <b>Valor 1</b>
              <input type="number"  inputmode="numeric" id="values_id_1" class="swal2-input" placeholder="Valor">
            </div>
            <button style="margin-left: 10px; background-color: red; color: white;" id="remove_1">x</button>
          </div>
      </div>
      <a id="addButton" style="color: #2196F3;display: flex;text-decoration: underline;">Agregar Campos +</a>
      `,
    confirmButtonColor: '#0e658e',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
        onBeforeOpen: (modalElement) => {
          const addButton = modalElement.querySelector('#addButton') as HTMLButtonElement;
          const container = modalElement.querySelector('#inputsContainer')  ;
          const addNewInput = this.addInput(container);
          addButton.addEventListener('click', addNewInput);
          const removeButton = modalElement.querySelector('#remove_1') as HTMLButtonElement;
          removeButton.addEventListener('click', () => {
            const field = document.getElementById('field_1');
            field?.remove(); // Remove the entire div
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const values: { percentage: string; value: string }[] = [];
          const inputs = document.querySelectorAll('[id^=porcentaje_]');
          
          inputs.forEach(input => {
            const idParts = input.id.split('_');
            const index = idParts[idParts.length - 1];
            const percentage = (document.getElementById(`porcentaje_${index}`) as HTMLInputElement).value;
            const value = (document.getElementById(`values_id_${index}`) as HTMLInputElement).value;
            if(percentage == "" || value == ""){
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Los campos vacios no se guardan',
                showConfirmButton: false,
                timer: 3600
              });
              return;
            }
            values.push({ percentage, value });
            this.bonus_metas = values
            
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Comision creada',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            })


          });

         

        }
      })
    }

   
  }

  async enviarFormularioNomina(){
    const btn_enviar = (document.getElementById('submit-fomrs') as HTMLInputElement);
    btn_enviar.style.display = "none";
    const token = this.getCookie("_token")
    const sucursal_id = (document.querySelector('#sucursal_id') as HTMLSelectElement).value;
    const frequency = (document.querySelector('#frecuencia') as HTMLSelectElement).value;
    const date_inicial = (document.querySelector('#numero') as HTMLSelectElement).value;
    const salary = (document.querySelector('#values_wages') as HTMLInputElement).value;
    const meta = (document.querySelector('#meta') as HTMLInputElement).value;
    var bono_select = "no";
    const details = (document.querySelector('#detalle_nomina') as HTMLInputElement).value.toLowerCase();
    const items = this.itemsContainerRef2.nativeElement.children;
    this.valorNumeroSeleccionadoBono = [];
    for (let i = 1; i < items.length; i++) {
      const item = items[i];
      if (item.classList.contains('checked2')) {
        const itemValue = item.querySelector('.item-monto.monto-sele .item-text2');
        const itemText = itemValue.getAttribute('data-value');
        this.valorNumeroSeleccionadoBono.push(itemText);
      }
    }
    if(this.valorNumeroSeleccionadoBono.length === 0){
    }
    else{
     bono_select = "si";
   }

   let date = ''
   if(frequency == 'quincenal'){
     const dateInicial = parseInt(date_inicial, 10);
     const seg_date = dateInicial + 15;
     date = dateInicial + '/' + seg_date;
   }
   if(frequency == 'semanal'){
       date = date_inicial
   }

   if(frequency == 'mensual'){
     date = date_inicial
   }

   if (!frequency || !date_inicial || !salary || !details || !sucursal_id) {
     Swal.fire({
       position: 'top-end',
       icon: 'error',
       title: 'Llena todos los campos...',
       showConfirmButton: false,
       timer: 3600
     });
     btn_enviar.style.display = "inline";
     return;
   }
   const formdataNomina= {
     date,
     frequency,
     details,
     meta,
     salary,
     sucursal_id
   };


  if (!isNaN(parseFloat(salary))) {
    console.log("El valor es numérico");
  } else if (typeof salary === 'string') {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Valor fijo no deje ingresar este tipo de datos',
      showConfirmButton: true,  // Mostrar el botón "OK"
      confirmButtonText: 'OK',  // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
    });
    return;
  } 


   try {
     const response =  await fetch(this.url.Ms1 + "nomina", {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token
       },
       body: JSON.stringify({
         "wage":{"date":date,"frequency":frequency,"details":details,"salary":salary,"sucursal_id": sucursal_id},
         "goals":this.bonus_metas,
         "bonus":this.valorNumeroSeleccionadoBono
       })
     });
       if (response.ok) {
         
         Swal.fire({
           position: 'top-end',
           icon: 'success',
           title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
           confirmButtonText: 'OK',  // Texto del botón "OK"
           allowOutsideClick: false, // Evitar el clic fuera del cuadro
           allowEscapeKey: false,    // Evitar la tecla "esc"
           confirmButtonColor: '#0e658e',
       }).then((result) => {
         if (result.isConfirmed) {
           window.location.reload()
         }
       });
       
     } else {
       Swal.fire({
         position: 'top-end',
         icon: 'error',
         title: 'Error al guardar el formulario...\n\n',
         text: 'Al parecer esta Nomina ya se encuentra registrada.',
          showConfirmButton: true,  // Mostrar el botón "OK"
           confirmButtonText: 'OK',  // Texto del botón "OK"
           allowOutsideClick: false, // Evitar el clic fuera del cuadro
           allowEscapeKey: false,    // Evitar la tecla "esc"
           confirmButtonColor: '#0e658e',
       }).then((result) => {
         if (result.isConfirmed) {
             btn_enviar.style.display = "inline";
             return
         }
     });
     }
   } catch (error) {
      btn_enviar.style.display = "inline";
     console.error('Error al enviar el formulario', error);
   }



  
  }

  click_checks2(event: Event) {
    const target = event.currentTarget as HTMLElement;
    const itemId = target.querySelector('.item-text2')?.getAttribute('data-value');

    if (itemId) {
        // Alternar el estado seleccionado
        if (this.selectedConceptId.includes(Number(itemId))) {
            this.selectedConceptId = this.selectedConceptId.filter(id => id !== Number(itemId));
        } else {
            this.selectedConceptId.push(Number(itemId));
        }
    }
}

  async listGoals() : Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "goals", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.valueBonos = jsonResponse;
         return this.valueBonos;
     }


  }

  async EditConcept(){
      this.listConcept = !this.listConcept
      this.vaciarCampos()
  }


  async SelectOption(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_concepts') as HTMLSelectElement).value;
    const response = await fetch(this.url.Ms1 + "wages_config_by_id/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    let result = await response.json()
    let nombre_nomina = (document.getElementById('detalle_nomina') as HTMLInputElement);
    let valor = (document.getElementById('values_wages') as HTMLInputElement);
    let frecuencia = (document.getElementById('frecuencia') as HTMLSelectElement);
    let metas = (document.getElementById('meta') as HTMLSelectElement);
    let numero = (document.getElementById('numero') as HTMLSelectElement);

    nombre_nomina.value = result[0].details
    valor.value = result[0].salary
    frecuencia.value = result[0].frequency
    this.frecuenciaSeleccionada = result[0].frequency

    if(result[0].frequency == "quincenal"){
      const [part1, part2] = result[0].date.split('/');
      this.valorNumeroSeleccionado = part1
    }
    if(result[0].frequency == "mensual"){
      this.valorNumeroSeleccionado = result[0].date;
    }
    if(result[0].frequency != "mensual" && result[0].frequency != "quincenal"){
      this.valorNumeroSeleccionado = result[0].date;
    }

    if(result[0].goals_config){
      const bonos_pk = result[0].goals_config.map((bono: any) => bono.id);
      bonos_pk.forEach((id: number) => {
        const itemText2Element = document.querySelector(`.monto-sele .item-text2[data-value="${id}"]`);
        if (itemText2Element) {
          // Obtener el elemento .monto-sele que envuelve a .item-text2 y agregar la clase
          const montoSeleElement = itemText2Element.closest('.monto-sele');
          if (montoSeleElement) {
            montoSeleElement.classList.add('checked2');
          }
        }
      });

    }

    if(result[0].bonos_config.length > 0){
      metas.value = "si"
      this.bonus_metas = result[0].bonos_config
      this.IsMeta = true;
    }
    else{
      metas.value = "no"
      this.IsMeta = false;
    }
  }

async actualizarNomina(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_concepts') as HTMLSelectElement).value;
    const frequency = (document.querySelector('#frecuencia') as HTMLSelectElement).value;
    const date_inicial = (document.querySelector('#numero') as HTMLSelectElement).value;
    const salary = (document.querySelector('#values_wages') as HTMLInputElement).value;
    const meta = (document.querySelector('#meta') as HTMLInputElement).value;
    const sucursal_id = (document.querySelector('#sucursal_id') as HTMLSelectElement).value; 
    var bono_select = "no";
    const details = (document.querySelector('#detalle_nomina') as HTMLInputElement).value.toLowerCase();
    const items = this.itemsContainerRef2.nativeElement.children;
    this.valorNumeroSeleccionadoBono = [];
    for (let i = 1; i < items.length; i++) {
      const item = items[i];
      if (item.classList.contains('checked2')) {
        const itemValue = item.querySelector('.item-monto.monto-sele .item-text2');
        const itemText = itemValue.getAttribute('data-value');
        this.valorNumeroSeleccionadoBono.push(itemText);
      }
    }
    if(this.valorNumeroSeleccionadoBono.length === 0){
    }
    else{
     bono_select = "si";
   }

    let date = ''
    if(frequency == 'quincenal'){
      const dateInicial = parseInt(date_inicial, 10);
      const seg_date = dateInicial + 15;
      date = dateInicial + '/' + seg_date;
    }
    if(frequency == 'semanal'){
        date = date_inicial
    }
 
    if(frequency == 'mensual'){
      date = date_inicial
    }
    
    try {
      const response =  await fetch(this.url.Ms1 + "wages", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          "wage_id": id,
          "sucursal_id": sucursal_id,
          "details": details,
          "date": date,
          "frequency": frequency,
          "salary":salary,
          "meta": meta,
          "bonus": bono_select,
          "bonus_data": bono_select === "si" ? this.valorNumeroSeleccionadoBono : [],
          "metas_data": meta === "si"
          ? this.bonus_metas
          : [],
        })
      });
      if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Nomina actualizada',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        });
      }
    }
    catch{
      console.log("#")
    }


}
  
async sucursal_id(id:any) {
  const token = this.getCookie("_token")
  const response = await fetch(this.url.Ms1 + "sucursal/" + id, {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token,
       "ngrok-skip-browser-warning": "69420"
     },
   });
     if (response.ok) {
       const jsonResponse = await response.json();
       this.datos = [jsonResponse]
   }
  }

  async getSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
   }

  async NominaSucursal(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#sucursal_id') as HTMLSelectElement).value;
      const response = await fetch(this.url.Ms1 + "nomina_sucursal/"+ id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_wages = jsonResponse;
     }
  }

  vaciarCampos() {
    const form = document.forms.namedItem('miFormularioNomina') as HTMLFormElement;
    if (form) {
        for (let i = 0; i < form.elements.length; i++) {
            const element = form.elements[i] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
            if (element.tagName === 'INPUT') {
                const inputType = element.type;
                if (inputType === 'text' || inputType === 'number' || inputType === 'email' || inputType === 'tel'|| inputType === 'date') {
                    element.value = '';
                }
            } else if (element.tagName === 'SELECT') {
                const selectElement = element as HTMLSelectElement;
                selectElement.selectedIndex = 0;
            } 
        }
    }
  }


}
