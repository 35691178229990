import { Component, EventEmitter, Output, ComponentFactoryResolver, ViewContainerRef,ComponentRef  } from '@angular/core';
import { Router } from '@angular/router';
import { FormRutaComponent } from '../form-ruta/form-ruta.component';
import Swal from 'sweetalert2';
import { FormSucursalComponent } from '../form-sucursal/form-sucursal.component';
import { FormFestivosComponent } from '../form-festivos/form-festivos.component';
import { Url, Permission } from '../service/data.service';
@Component({
  selector: 'app-super-usuario',
  templateUrl: './super-usuario.component.html',
  styleUrls: ['./super-usuario.component.css']
})
export class SuperUsuarioComponent {
  sucursal = [{ label: 'Nueva Sucursal' }];
  isRotated = false;
  isConfigOpen = false;
  showListSucursa = true;
  permissions: any = {}
  
  @Output() llamarFuncion = new EventEmitter<void>();

  constructor(private router: Router,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {}

  onMenuItemClick(item: any) {
    if (item === 'Usuario') {
      this.router.navigate(['form-usuario']);
    } else if (item === 'Ruta') {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormRutaComponent);
      const componentRef: ComponentRef<FormRutaComponent> = this.viewContainerRef.createComponent(componentFactory);
      Swal.fire({
        title: 'Rutas',
        showCloseButton: true,
        showConfirmButton: false,  // Mostrar el botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        html:componentRef.location.nativeElement
      })
    }else if (item === 'Festivos'){

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormFestivosComponent);
      const componentRef: ComponentRef<FormFestivosComponent> = this.viewContainerRef.createComponent(componentFactory);
      Swal.fire({
        title: 'Crear Festivos',
        showCloseButton: true,
        showConfirmButton: false,  // Mostrar el botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        html:componentRef.location.nativeElement
      })

    }
    else if(item === "Concepto"){
      this.router.navigate(['form-concepto']);
    }
    else if(item === "Nomina"){
    
      let html_c = `
          <div id="action-buttons" style="display: flex;justify-content: space-around;">
          <button style="border:none;color: #ffff;background: #0e658e !important;border-radius: 6px;padding: 3px 12px;" id="btn-create">Crear</button>
          <button style="border:none;color: #ffff;background: #0e658e !important;border-radius: 6px;padding: 3px 12px;" id="btn-bonos">Bonos</button>
            <button style="border:none;color: #ffff;background: #0e658e !important;border-radius: 6px;padding: 3px 12px;" id="btn-list">Listar</button>
        </div>
        `;
      Swal.fire({
        title: 'Selecciona una accion',
        showConfirmButton: false,  // Mostrar el botón "OK"
        html:html_c,
        showCloseButton: true,
        onOpen: () => {
          const btnCreateModal = document.getElementById('btn-create');
          const btnListModal = document.getElementById('btn-list');
          const btnBonosModal = document.getElementById('btn-bonos');
          
          if (btnCreateModal) {
              btnCreateModal.addEventListener('click', () => {
                this.router.navigate(['nomina']);
                Swal.close()
              }
            );
          }
          if (btnListModal) {
              btnListModal.addEventListener('click', () => {
                this.router.navigate(['list-nominas']);
                Swal.close()
              }
            );
          }
          if (btnBonosModal) {
              btnBonosModal.addEventListener('click', () => {
                this.router.navigate(['bonos']);
                Swal.close()
              }
            );
          }
        },
      })
    
    }
  }

  ngOnInit(): void {
    this.permissions = Permission.ElementPermissionObjects()
  }

  onSucursal(item: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormSucursalComponent);
      const componentRef: ComponentRef<FormSucursalComponent> = this.viewContainerRef.createComponent(componentFactory);
      Swal.fire({
        title: 'Sucursal',
        showCloseButton: true,
        showConfirmButton: false,  // Mostrar el botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        html:componentRef.location.nativeElement
      })
  }


  goBack() {
    // this.router.navigateByUrl('');
  }

  openConfig() {
    this.isRotated = !this.isRotated;
    this.isConfigOpen = !this.isConfigOpen;
  }


  funcionLlamada(){
   this.showListSucursa = false;
    this.llamarFuncion.emit();
  }

}
