import { Component, OnInit } from '@angular/core';
import { Url } from '../service/data.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { FormLoansComponent } from '../form-loans/form-loans.component';
import { ImagenClientComponent } from '../imagen-client/imagen-client.component';

import { ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
@Component({
  selector: 'app-form-cliente',
  templateUrl: './form-cliente.component.html',
  styleUrls: ['./form-cliente.component.css'],
})
export class FormClienteComponent  implements OnInit {
  dato: any;
  esEdicion = false;
  prefix:any = "";
  isBrazil:any = false;
  constructor(public url: Url, private router: Router,private route: ActivatedRoute,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
        let time = this.getCookie("_IdCountry")
        this.prefix =  `${this.getCookie("_Prefix")} `;
        const phoneInput = document.getElementById('phone') as HTMLInputElement;
        if (phoneInput) {
          phoneInput.value = this.prefix;
        }
        if (time === "BR") {
          this.isBrazil = true;
        }
        this.CountryImg(time)
        this.esEdicion = !!this.dato;
        if (this.dato) {
          const names = (document.querySelector('#name') as HTMLSelectElement);
          const surname = (document.querySelector('#surname') as HTMLSelectElement);
          const phone = (document.querySelector('#phone') as HTMLSelectElement);
          const address = (document.querySelector('#address') as HTMLSelectElement);
          names.value = this.dato.name
          surname.value = this.dato.customer_detail_id.surname
          phone.value = this.dato.phone
          address.value = this.dato.customer_detail_id.address
        }
   
  }


   
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  async CountryImg(id:any){
      if (id !== "Timezone not found") {
        try {
            const response = await fetch(`https://restcountries.com/v3.1/alpha/${id}`);
            const result = await response.json();
            const flagUrl = result[0]?.flags?.png;
            if (flagUrl) {
                const imgElement = document.querySelector('#basic-addon1 img') as HTMLImageElement;
                if (imgElement) {
                    imgElement.src = flagUrl;
                    imgElement.alt = `${result[0]?.name?.common} flag`;
                }
            }
        } catch (error) {
            console.error("Error fetching country data:", error);
        }
    }
  }
     
  enforcePrefix() {
    const phoneInput = document.getElementById('phone') as HTMLInputElement;
    let value = phoneInput.value;
    const prefixWithAreaCode = this.prefix;
    const basePrefix = prefixWithAreaCode.substring(0, 5);
    const basePrefix_sig = prefixWithAreaCode.substring(0, 4);
    let currentAreaCode = prefixWithAreaCode.substring(5, prefixWithAreaCode.indexOf(')'));
  
    if (this.isBrazil) {
      if (!value.startsWith(basePrefix)) {
        phoneInput.value = prefixWithAreaCode;
        return;
      }
  
      // Extraer el código de área entre paréntesis y limitar a 2 caracteres
      const regexAreaCode = /\(\d*/;
      const match = value.match(regexAreaCode);
  
      if (match) {
        let areaCodePart = match[0]; // Parte que contiene el código de área hasta el punto actual
        const areaCodeDigits = areaCodePart.replace(/\D/g, ""); // Solo dígitos dentro del código de área
        if (areaCodeDigits.length > 2) {
          const truncatedAreaCode = areaCodeDigits.slice(0, 2); // Limitar a 2 caracteres
          value = value.replace(regexAreaCode, `(${truncatedAreaCode}`);
          phoneInput.value = value;
        }
      }
      let areaCodePart = value.substring(4, value.indexOf(')') + 1); 
      if (areaCodePart === '() ') {
        areaCodePart = `(${currentAreaCode}) `;
        let numberPart = value.substring(value.indexOf(')') + 2).replace(/\D/g, ""); 
        if (numberPart.length > 5) {
          numberPart = numberPart.slice(0, 5) + '-' + numberPart.slice(5);
        }
        phoneInput.value = `${basePrefix_sig}${areaCodePart} ${numberPart}`;
        return;
      }
      // Verificar si el código de área termina con ')'
      if (!areaCodePart.endsWith(')')) {
        if(!basePrefix_sig.startsWith(basePrefix)){
          phoneInput.value = `${basePrefix}${currentAreaCode}) `;
          return
        }else{
          phoneInput.value = `${basePrefix_sig}${currentAreaCode}) `;
          return
        }
      }
  
      let numberPart = value.substring(value.indexOf(')') + 2).replace(/\D/g, "");
      if (numberPart.length > 5) {
        numberPart = numberPart.slice(0, 5) + '-' + numberPart.slice(5);
      }
  
      phoneInput.value = `${basePrefix_sig}${value.substring(4, value.indexOf(')') + 1)} ${numberPart}`;
      return;
    } else {
      if (!value.startsWith(this.prefix)) {
        phoneInput.value = this.prefix;
        return;
      }
      let numberPart = value.replace(this.prefix, "").replace(/\D/g, ""); 
      
      phoneInput.value = this.prefix + numberPart;
      return;
    }
  }

   async addLoanMode() {
    const token = this.getCookie("_token")
    const routes_id =  this.getCookie("_route")
    const prefixWithAreaCode = this.prefix;
    let fecha = new Date();
    let dia = fecha.getDate().toString().padStart(2, '0');
    let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    let año = fecha.getFullYear().toString();
    let dates =  `${año}-${mes}-${dia}`;


    const identify = (document.querySelector('#identify') as HTMLSelectElement).value;
    const names = (document.querySelector('#name') as HTMLSelectElement).value;
    const surname = (document.querySelector('#surname') as HTMLSelectElement).value;
    const phone = (document.querySelector('#phone') as HTMLSelectElement).value;
    const address = (document.querySelector('#address') as HTMLSelectElement).value;
  
  
    //const images4 = (document.getElementById("images4") as HTMLInputElement).files;
    const data = {
      id: identify,
      date: dates,
      name: names,
      phone: phone,
      surname: surname,
      address: address,
      routes_id: routes_id,
    };

    if(phone == prefixWithAreaCode){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Ingresa un numero telefonico",
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }

    if (!isNaN(parseFloat(phone))) {
      console.log("El valor es numérico");
    } else if (typeof phone === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Solo ingresar numeros",
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 


    if(!identify || !names || !phone){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,

      });
      return;
    }

    
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    try {
      const response = await fetch(this.url.Ms1 + "cliente", {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        },
        body: formData
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Cliente Creado',
          text: 'Ingresa las imagenes',
          showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        }).then(async (result) => {
          if (result.isConfirmed) {
            let result = await response.json()
            let id_customer = result.id
            let info = {
              "id": result.id,
              "name": names,
            }
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ImagenClientComponent);
            const componentRef: ComponentRef<ImagenClientComponent> = this.viewContainerRef.createComponent(componentFactory);
            componentRef.instance.dni = id_customer
            componentRef.instance.dato = info
            Swal.fire({
              title: 'Subir Imagenes',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,   
              showCloseButton: true,
              html:componentRef.location.nativeElement
            })
            
            // this.router.navigate(['list-loans']);
          }
          else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'error!',
              text: 'No se pude crear el cliente',
              showConfirmButton: true,
            })
          }
        });

      }
      else{
        let resultado = await response.json()
        if(resultado.Status == "error"){
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: resultado.Message,
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          });
          return;
        }
        
      }

     } catch (error) {
        console.error('Error en la solicitud', error);
     }


   }

  async actualizar_customer(){
    const token = this.getCookie("_token")
    const names = (document.querySelector('#name') as HTMLSelectElement).value;
    const surname = (document.querySelector('#surname') as HTMLSelectElement).value;
    const phone = (document.querySelector('#phone') as HTMLSelectElement).value;
    const address = (document.querySelector('#address') as HTMLSelectElement).value;
    const cliente = this.dato.id
    const detalle = this.dato.customer_detail_id.id

    const client = {
      names,
      phone,
      surname,
      address,
      detalle,
      cliente
    };

    if (!isNaN(parseFloat(phone))) {
      console.log("El valor es numérico");
    } else if (typeof phone === 'string') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Solo ingresar numeros",
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 


    try {
      const response = await fetch(this.url.Ms1 + 'cliente_update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(client),
      });
      if (response.ok) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Guardando...',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()     
          }
        });
      }

    }
    catch{

    }
  }

  convertToUppercase(name: any) {
    const input = document.getElementById(name) as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }
}
