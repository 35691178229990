
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h2 style="display: inline-block;">Grupo Nomina</h2>
        <a class="link-buttonAdd2" (click)="EditConcept()" *ngIf="!listConcept" >Editar</a>
        <a class="link-buttonAdd" (click)="EditConcept()" *ngIf="listConcept" >Crear +</a>
        <form autocomplete="off" class="form" name="miFormularioNomina" [formGroup]="miFormularioNomina" (ngSubmit)="enviarFormularioNomina()">
        
        <br>

        <div class="col-lg-8 col-12 inputs">
          <label class="col-lg-3 col-form-label form-control-label" >Sucursal</label>
          <select class="form-control form-county" id="sucursal_id" name="sucursal_id" (change)="NominaSucursal()">
            <option value="" disabled selected>--Seleccionar--</option>
            <option *ngFor="let mode of datos" value="{{mode.id}}">{{mode.name}}</option>
          </select>
        </div>
    
        <div class="col-lg-8 col-12 inputs" *ngIf="listConcept">
            <label class="col-lg-3 col-form-label form-control-label" >Buscar nomina</label>
            <select class="form-control form-county" id="list_concepts"  (change)="SelectOption()" >
              <option value="" disabled selected>--Seleccionar--</option>
              <option *ngFor="let mode of this.datos_wages" value="{{mode.id}}">{{mode.details}}</option>
            </select>
        </div>
      

        


          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label" >Nombre grupo nomina</label>
            <div class="col-lg-8 col-12 inputs">
             <input  class="form-control input-form" type="text" id="detalle_nomina" placeholder=""  formControlName="details" style="text-transform: uppercase;">
            </div>
          </div>
          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Valor fijo</label>
            <div class="col-lg-8 col-12 inputs">
              <input id="values_wages" name="values_wages" formControlName="values_wages" class="form-control input-form" type="number"  inputmode="numeric" placeholder="Valor">
            </div>
          </div>
          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Frecuencia</label>
            <div class="col-lg-8 col-12 inputs">
              <select name="frecuencia" id="frecuencia" class="form-control input-form" [(ngModel)]="frecuenciaSeleccionada" formControlName="frecuencia"> 
                <option value="">--Seleccionar--</option>
                <option value="semanal">Semanal</option>
                <option value="quincenal">Quincenal</option>
                <option value="mensual">Mensual</option>
              </select>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Escoge el dia</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control input-form" id="numero" name="numero" [(ngModel)]="valorNumeroSeleccionado"  formControlName="numero">
                <option value="0">--Seleccionar--</option>
                <option *ngFor="let valor of valoresNumero[frecuenciaSeleccionada]" [value]="valor">{{ valor }}</option>
              </select>
             
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label" >Meta + comisión:</label>
            <div class="col-lg-8 col-12 inputs">
              <select name="meta" id="meta" formControlName="meta" class="form-control input-form" (change)="metaIsd()">
                <option value="">--Seleccionar--</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
              <button class="btn btn-primary subtmit-bt2" *ngIf="IsMeta" type="button" (click)="add_bonus()">Ver metas</button>
            </div>
          </div>

     

          <div class="form-group row " id="conten_bonos">
            <label class="col-lg-3 col-form-label form-control-label">Bonos</label>
            <div class="container-select">
              <div class="select-btn" id="bono-multiple" #selectBtn2>
                <span class="btn-text2" #btnText2>Bonos</span>
              </div>

              <ul class="list-items" #itemsContainer2>
                <li  class="item-monto" (click)="click_checks_Total($event)">
                  <span class="checkbox">
                    <i class="fa-solid fa-check check-icon" ></i>
                  </span>
                  Todos
                </li>
            
                <li class="item-monto monto-sele" *ngFor="let routes of valueBonos"  #itemMonto
                [ngClass]="{'checked2': selectedConceptId.includes(routes.id)}"  (click)="click_checks2($event)">
                  <span class="checkbox">
                    <i class="fa-solid fa-check check-icon"></i>
                  </span>
                  <span class="item-text2" [attr.data-value]="routes.id">{{ routes.name }}</span>
                </li>
              </ul>

              
            </div>
          </div>



          
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs" style="margin-bottom: 160px; text-align: center;">
              <input class="btn btn-primary subtmit-bt" id="submit-fomrs" type="submit" *ngIf="!listConcept" value="Guardar">
              <input class="btn btn-primary subtmit-bt" *ngIf="listConcept" type="btn" value="Actualizar" (click)="actualizarNomina()">
              
            </div>
          </div>
        </form>
      </div>
    </div>
  
  </div>
  
</div>
