import { Component, EventEmitter, Output, ComponentFactoryResolver, ViewContainerRef,ComponentRef  } from '@angular/core';
import { faArrowDown, faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import { NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
import { FormSucursalComponent } from '../form-sucursal/form-sucursal.component';
@Component({
  selector: 'app-list-sucursal',
  templateUrl: './list-sucursal.component.html',
  styleUrls: ['./list-sucursal.component.css']
})

export class ListSucursalComponent {
  @Output() llamarFuncion = new EventEmitter<void>();

  constructor(private router: Router,public url: Url,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {}
  selectedItems: any[] = [];
  faArrowDown = faArrowDown;
  faArrowRight = faArrowRight;
  isConfigOpen = false;
  isRotated = false;
  permissions: any = {}


  ngOnInit(): void {
    this.getSucursal();
    this.permissions = Permission.ElementPermissionObjects()
  }
  
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  datos: any[] = [];
  async getSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         "Authorization": `Bearer ${token}`,
         "ngrok-skip-browser-warning": "69420"
       },
     });
     if (response.ok) {
      const jsonResponse = await response.json();
      this.datos = jsonResponse;

      return this.datos;
    }   
    
    
   }

  
  
  verInfoSucursal(dato: any) {
    dato.isRotated = !dato.isRotated;
    dato.isConfigOpen = !dato.isConfigOpen;
    const index = this.selectedItems.indexOf(dato.name);
    if (index >= 0) {
        this.selectedItems.splice(index, 1);
    } else {
        this.selectedItems.push(dato.name);
    }
  }
  
  
  mostrar_sucursal(dato: any) {
    this.llamarFuncion.emit();
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    document.cookie = "_inicio" + "=" + dato.working_time + ";" + expiresStr + ";path=/";
    document.cookie = "_final" + "=" + dato.date_finysh_day + ";" + expiresStr + ";path=/";
    this.router.navigate(['list-rutas', dato.id]);
  }  

  ver_usuarios_de_sucursales(dato: any){
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    document.cookie = "_branch" + "=" + dato + ";" + expiresStr + ";path=/";
    this.router.navigate(['lista-userSucursal']);
  }

  cash_views(id: any, branch_id:any){
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    document.cookie = "_branch" + "=" + branch_id + ";" + expiresStr + ";path=/";

    this.router.navigate(['cash-branch', id]);
  }

  borrar( dato: any ){
    const token = this.getCookie('_token');
    Swal.fire({
      title: `¿Seguro quieres eliminar la sucursal ${dato.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, eliminar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(this.url.Ms1 + "sucursal/" + String(dato.id), {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        let resultados_resp = await response.json();
        if (resultados_resp.message == "La sucursal tiene rutas") {
            Swal.fire({
              icon: 'error',
              title: 'La sucursal tiene rutas activas \n',
              showConfirmButton: false,
            })
        }else{
          Swal.fire({
            icon: 'success',
            title: 'Sucursal eliminada \n',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }
      }
    })
  }
  
actualizar(dato: any) {
  // Enviamos el dato a la siguiente vista

  const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormSucursalComponent);
  const componentRef: ComponentRef<FormSucursalComponent> = this.viewContainerRef.createComponent(componentFactory);
  componentRef.instance.dato = dato

  Swal.fire({
    title: 'Sucursal',
    showCloseButton: true,
    showConfirmButton: false,  // Mostrar el botón "OK"
    allowOutsideClick: false, // Evitar el clic fuera del cuadro
    allowEscapeKey: false,    // Evitar la tecla "esc"
    html:componentRef.location.nativeElement
  })
}
}
