
<div class="menu-container">

<app-menu-super-user></app-menu-super-user>

  <br>
  <div class="list-sucursa">
    <div class="card card-outline-secondary">
 
      <div class="card-body data-content">
        <h2 style="display: inline-block;">Plan de cuentas</h2>
        <a class="link-buttonAdd2" (click)="EditConcept()" *ngIf="!listConcept && (!permissions.hasOwnProperty('btn_concepto_actualizar') || !permissions.hasOwnProperty('btn_concepto_eliminar'))"  >Editar o eliminar +</a>
        <a class="link-buttonAdd" (click)="EditConcept()" *ngIf="listConcept" >Crear +</a>

        <form autocomplete="off" class="form" name="miFormularioSucursal">
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Sucursal</label>
            <div class="col-lg-8 col-12 inputs">
              <select  class="form-control form-county" name="sucursa_ids" id="sucursa_ids" (change)="ConceptSucursal()">
                <option value="" disabled selected>--Seleccionar--</option>
                <option *ngFor="let sucursal of datos" [value]="sucursal.id">{{ sucursal.name }}</option>
              </select>
            </div>
          </div>


          <div class="form-group row" *ngIf="listConcept">
            <label class="col-lg-3 col-form-label form-control-label">Lista de plan de cuentas</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control form-county" id="list_concepts" (change)="SelectOption()" >
                <option value="" disabled selected>--Seleccionar--</option>
                <option *ngFor="let mode of datos_mode" [value]="mode.income_expense_configuration_id.id" [attr.data-type]="mode.income_expense_configuration_id.type" >
                  {{ mode.income_expense_configuration_id.name}}
                </option>


             </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Nombre concepto</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form form-capite" type="text" id="nombre_concepto" (blur)="convertCapilize('nombre_concepto')" name="nombre_concepto" placeholder="Nombre">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label ">Tipo de plan de cuentas</label>
            <div class="col-lg-8 col-12 inputs">
                <select class="form-control form-county" id="type_concept" >
                    <option value="" disabled selected>--Seleccionar--</option>
                    <option value="Ingresos">Ingresos</option>
                    <option value="Egresos">Egresos</option>
                 </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label ">Periodo</label>
            <div class="col-lg-8 col-12 inputs">
              <select class="form-control form-county" id="periods" >
                <option value="" disabled selected>--Seleccionar--</option>
                <option value="Diario">Diario</option>
                <option value="Semanal">Semanal</option>
                <option value="Quincenal">Quincenal</option>
                <option value="Mensual">Mensual</option>
             </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Detalle</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form form-county" type="text" id="detalle" (blur)="convertToUppercase('detalle')"  name="detalle" placeholder="Detalle">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label">Tope</label>
            <div class="col-lg-8 col-12 inputs">
              <input class="form-control input-form form-county" type="text" id="tope_cash" name="tope_cash"  (blur)="convertToUppercase('tope_cash')" placeholder="Cantidad maxima del concepto">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-3 col-form-label form-control-label"></label>
            <div class="col-lg-8 col-12 inputs" style="margin-bottom: 100px !important;display: flex;justify-content: center;">
              <input class="btn btn-primary subtmit-bt" id="submits-save" type="button" (click)="CreateConcepto()" value="Guardar" *ngIf="!listConcept">
              <button class="btn btn-primary subtmit-bt1" *ngIf="listConcept && !permissions.hasOwnProperty('btn_concepto_actualizar')" (click)="updateConcept()">Actualizar</button>
              <button class="btn btn-primary subtmit-bt2" *ngIf="listConcept && !permissions.hasOwnProperty('btn_concepto_eliminar')" (click)="DeleteConcept()">Eliminar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  
  </div>
  
</div>
