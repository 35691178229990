import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import { Url } from '../service/data.service';

@Component({
  selector: 'app-list-movimiento-sucursal',
  templateUrl: './list-movimiento-sucursal.component.html',
  styleUrls: ['./list-movimiento-sucursal.component.css'],
})
export class ListMovimientoSucursalComponent  implements OnInit {
  datos: any = [];
  constructor(private router: Router,public url: Url, private route: ActivatedRoute) {}

  ngOnInit() {
    this.getMovimientos()
  }
  isToday(motionDate: string): boolean {
    const today = new Date();
    const motionDateObj = new Date(Date.parse(motionDate));
    const motionDateISO = motionDateObj.toISOString();
    const todayISO = today.toISOString();
    return todayISO.slice(0, 10) === motionDateISO.slice(0, 10);
  }
  
  typeName(dato_l:any){
    if(dato_l.routes_id !== null){
      if(dato_l.income_expense_configuration_id.name == "Inversion"){
        return "Traslado Ruta"
      }
    }else{
      return dato_l.income_expense_configuration_id.name
    }
    }
    

  showReceipt(data:any){
    const imagenHTML = '<img style="max-width:300px; max-heigth:300px;" src=" ' + this.url.Ms1 +"image_view/"+ data.path + '" alt="Imagen">';
    Swal.fire({
      title: 'Comprobante:',
      html: imagenHTML
    });
  }

  formatearNumero(numero:any) {
    let data = parseFloat(numero);
    let formattedNumber = data.toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    });
    return formattedNumber?.startsWith("-") ? "-$" + formattedNumber.slice(1) : "$" + formattedNumber;
  }

  fechaFormateada(fecha:any){
    let dates = new Date(fecha)
    const localDate = new Date(dates.getTime() + dates.getTimezoneOffset() * 60000);
    const dia = localDate .getDate();
    const mes = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const año = localDate.getFullYear();
    return `${dia}/${mes}/${año}`;
}  

typeMovimiento(types:any){
  let values = ""
  switch (types) {
    case 'Base':
      values = 'Ingresos'
      break;
    case 'Retirada':
      values = 'Egresos'
      break;     
    case 'Transferir':
      values = 'Ingresos'
      break;
    case 'Inversion':
      values = 'Ingresos'
      break;
    case 'Egresos':
      values = 'Egresos'
      break;
    case 'Ingresos':
      values = 'Ingresos'
      break;
  }
  return values
}


  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  async getMovimientos(){
    const token =  this.getCookie('_token');
    let sucursal = this.route.snapshot.params['sucursal']

    const response = await fetch(this.url.Ms1 + "get_motions_by_branch/" + sucursal, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    });
    this.datos = await response.json()
    console.log(this.datos)
  }

  async DeleteMovimiento(id: any){
    Swal.fire({
      position: 'top-end',
      icon: 'info',
      title: '¿Desea eliminar este movimiento?',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#0e658e',
    }).then(async (response) => {
      if(response.isConfirmed){
        const token =  this.getCookie('_token');
        const response = await fetch(this.url.Ms1 + "delete_motion_by_id/" + id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        });
        if (response.ok){
          Swal.fire({
            icon: 'success',
            title: 'Movimiento eliminado',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then(async (response) => {
            window.location.reload()
          })

        }
      }
    })

   
  }

}
