import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
@Component({
  selector: 'app-form-bonos',
  templateUrl: './form-bonos.component.html',
  styleUrls: ['./form-bonos.component.css']
})
export class FormBonosComponent {
  router: any;
  datos: any[] = [];
  listConcept: boolean = false;
  frecuenciaSeleccionada: string = '';
  valorNumeroSeleccionado: any = 0;
  permissions: any ={}
  valoresNumero: any = {
    semanal: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
    quincenal: [],
    mensual: [],
    anual: []
  };
  miFormularioBonos: FormGroup = this.formBuilder.group({
    'nombre': ['', Validators.required],
    'valor_bono': ['', Validators.required],
    'frecuencia': ['', Validators.required],
    'numero': ['', Validators.required ],
    'retirada_select': ['', Validators.required ],
  });

  ngOnInit() {
    this.permissions = Permission.ElementPermissionObjects()
    const branch = this.getCookie("_branch")
      this.getSucursal();
  }


  constructor(private formBuilder: FormBuilder, public url: Url) {
    for (let i = 1; i <= 30; i++) {
      this.valoresNumero.mensual.push(i);
    }
    for (let i = 1; i <= 15; i++) {
      this.valoresNumero.quincenal.push(i);
    }
    for (let i = 1; i <= 30; i++) {
      this.valoresNumero.anual.push(i);
    }
  }
  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  async enviarFormularioBonos(){
    const token = this.getCookie("_token")
    const names = (document.querySelector('#names') as HTMLInputElement).value;
    const value = (document.querySelector('#value') as HTMLInputElement).value;
    const ventas = (document.querySelector('#ventas_bono') as HTMLInputElement).value;
    const frecuencia = (document.querySelector('#frecuencia') as HTMLInputElement).value;
    const numero = (document.querySelector('#numero') as HTMLInputElement).value;
    const retirada = (document.querySelector('#retirada_select') as HTMLInputElement).value;
    const button = (document.getElementById("subtmit-boton") as HTMLInputElement)
    const sucursal_id = (document.querySelector('#sucursa_ids') as HTMLInputElement).value;
    
    button.style.display = "none"

    if (!names || !ventas  || !frecuencia || !value || !numero || !retirada || !sucursal_id) {
      button.style.display = "inline-block"
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,
        timer: 3600
      });
      return;
    }

    if (!isNaN(parseFloat(value)) || !isNaN(parseFloat(ventas)) || !isNaN(parseFloat(retirada)) ) {
      console.log("El valor es numérico");
    } else if (typeof value === 'string' && typeof ventas === 'string' && typeof retirada === 'string' ) {
      button.style.display = "inline-block"
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Solo ingresar numeros',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 

    const formdataBonos = {
      // id: id,
      frequency: frecuencia,
      value:value,
      name: names,
      quantity_new: ventas,
      dates:numero,
      percentage_retiradas: retirada,
      sucursal_id
    };

    try {
      const response =  await fetch(this.url.Ms1 + "goals", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(formdataBonos)
      });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Guardando...',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          });
          window.location.reload();
        
      } else {
        button.style.display = "inline-block"
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al guardar el formulario...\n\n',
          text: 'Al parecer este Bono ya se encuentra registrado.',
          showConfirmButton: false,
          timer: 3600
        });
      }
    } catch (error) {
      button.style.display = "inline-block"
      console.error('Error al enviar el formulario', error);
    }

  }
 
  async EditBono(){
    this.listConcept = !this.listConcept
    this.vaciarCampos()

  }
  
  
  datos_bonos:any[] = [];
  async BonosSucursal(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#sucursa_ids') as HTMLInputElement).value;
    const response = await fetch(this.url.Ms1 + "bonus_sucursal/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    })
    this.datos_bonos = await response.json()
  }

  async sucursal_id(id:any) {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = [jsonResponse]
     }
    }


  async getSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
   }
  async updateBonus(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_bonus') as HTMLInputElement).value;
    const name = (document.querySelector('#names') as HTMLInputElement).value;
    const value = (document.querySelector('#value') as HTMLInputElement).value;
    const quantity_new = (document.querySelector('#ventas_bono') as HTMLInputElement).value;
    const percentage_retiradas = (document.querySelector('#retirada_select') as HTMLInputElement).value;
    const frequency = (document.querySelector('#frecuencia') as HTMLSelectElement).value;
    const dates = (document.querySelector('#numero') as HTMLSelectElement).value;   

    const formdata = {
      name,
      value,
      quantity_new,
      percentage_retiradas,
      frequency,
      dates
    };
    try {
      const response = await fetch(this.url.Ms1 + "bonus_list/" + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(formdata)
      });
      if (response.ok){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Actualizado',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        });
      }
    } catch (error) {
      console.error('Error al enviar el formulario', error);
    }
   }

   async getBonos(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_bonus') as HTMLInputElement).value;
    const names = (document.querySelector('#names') as HTMLInputElement);
    const value_bono = (document.querySelector('#value') as HTMLInputElement);
    const ventas_bono = (document.querySelector('#ventas_bono') as HTMLInputElement);
    const retirada_select = (document.querySelector('#retirada_select') as HTMLInputElement);
    const frecuencia = (document.querySelector('#frecuencia') as HTMLSelectElement);
    const numero = (document.querySelector('#numero') as HTMLSelectElement);   
    const response = await fetch(this.url.Ms1 + "bonus_list/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
          const jsonResponse = await response.json();
          names.value = jsonResponse.name
          value_bono.value = jsonResponse.value
          ventas_bono.value = jsonResponse.quantity_new
          retirada_select.value = jsonResponse.percentage_retiradas
          frecuencia.value = jsonResponse.frequency
          this.frecuenciaSeleccionada = jsonResponse.frequency
          this.valorNumeroSeleccionado = jsonResponse.dates

     }
   }
  vaciarCampos() {
    const form = document.forms.namedItem('miFormularioBonos') as HTMLFormElement;
    if (form) {
        for (let i = 0; i < form.elements.length; i++) {
            const element = form.elements[i] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
            if (element.tagName === 'INPUT') {
                const inputType = element.type;
                if (inputType === 'text' || inputType === 'number' || inputType === 'email' || inputType === 'tel'|| inputType === 'date') {
                    element.value = '';
                }
            } else if (element.tagName === 'SELECT') {
                const selectElement = element as HTMLSelectElement;
                selectElement.selectedIndex = -1;
            } 
        }
    }
  }


  async DeleteBonus(){
    const token = this.getCookie("_token")
    const id = (document.querySelector('#list_bonus') as HTMLSelectElement).value;

    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: '¿Seguro quieres eliminar este bono?',
      showConfirmButton: true,  // Mostrar el botón "OK"
      confirmButtonText: 'Si, eliminar',  // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      confirmButtonColor: '#0e658e',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(this.url.Ms1 + "bonus_list/" + id, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`
            },
          });
          let infos = await response.json()
          if ("error" in infos) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'No se puede eliminar este bono',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            }).then((result) => {
              if (result.isConfirmed) {
                return
              }
            });
          }
          else {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Bono eliminado',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            });
          }
        } catch (error) {
          console.error('Error al enviar el formulario', error);
    
        }
      }
    });

    

   }
}
