import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { Url } from '../service/data.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {ChangeViewLoans } from '../list-client/list-client.component';

@Component({
  selector: 'app-form-loans',
  templateUrl: './form-loans.component.html',
  styleUrls: ['./form-loans.component.css'],
})
export class FormLoansComponent  implements OnInit,AfterViewInit  {
  selectedDates: Date;
  selectedCliente: any;
  selectedItem: string | null = null;
  searchQuery: string = "";
  searchQuery2: string = "";
  showMenus = false;
  showMenus2 = false;
  selectedOption: string | null = null;
  selectedOption2: string | null = null;
  selectedMenus: any | null = null; 
  selectedMenus2: any | null = null; 
  selectMenuPass =  "";
  selectMenuPass2 =  "";
  frecuency_data = "";
  percentaje = "";
  cantidadOpciones: number = 0;
  listDaily: boolean = false;
  tope =  "";
  dato_agendar: any;
  status_agendar = false;
  id_agendar: any;
  options_seelec: any;
  loans: any = [];
  dato_ac:any = null;
  lat: any = "";
  long: any = "";
  name_Dis: boolean = false;

  get filteredItems(): any[] {
    return this.datos.filter(datos =>
      datos.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
}


get filteredItems2(): any[] {
  return this.loans.filter((datos:any) =>
    datos.customers_id.name.toLowerCase().includes(this.searchQuery2.toLowerCase())
  );

  
}
 

  constructor(public url: Url, private location: Location, private route: ActivatedRoute, private router: Router) { 
    this.selectedDates = new Date();
    this.searchQuery = '';
    this.datos = [];
  }

  async ngOnInit() {
    await this.getClient();
    await this.loansRuta();
    // this.getLocation();
    if (this.dato_ac != null) {
      this.dato_agendar = this.dato_ac
        this.status_agendar = true;
        var names = (document.getElementById("pruebas") as HTMLElement)
        names.classList.add("disable-inputs")
        this.id_agendar = this.dato_agendar.id
        const monto = (document.querySelector('#monto') as HTMLSelectElement);
        this.selectedOption =  this.dato_agendar.customers_id.name;
        this.selectedMenus =  this.dato_agendar;
        this.selectMenuPass =  this.dato_agendar.customers_id.id;
        monto.value = this.dato_agendar.value;
        this.getModalidad(this.selectMenuPass)
    }
    if(this.name_Dis == true){
      var names = (document.getElementById("pruebas") as HTMLElement)
      names.classList.add("disable-inputs")
    }
  
  }
  
  ngAfterViewInit() {

  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude
          this.long = position.coords.longitude
        },
        (error) => {
          console.error('Error al obtener la ubicación:', error);
        }
      );
    } else {
      console.error('La geolocalización no es compatible con este navegador.');
    }
  }

  onSelectChange(value: any) {
    console.log('Valor seleccionado:', value);
    // Realiza la lógica adicional aquí
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  
  datos: any[] = [];
  async getClient(): Promise<any> {
    const token = this.getCookie("_token")
    const ruta_id = this.getCookie("_route")
    const response = await fetch(this.url.Ms1 + "cliente/ruta/" + ruta_id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
  }

  async postLoan(){
    const button_guardar = (document.getElementById('enviar_form') as HTMLButtonElement) 
    button_guardar.style.display = "none";
    const token = this.getCookie("_token")
    const ruta = this.getCookie("_route")
    const cash_id = this.getCookie("_cash")
    var user_id = this.getCookie("_id")
    const cliente_id = this.selectMenuPass
    const date_initial = (document.querySelector('#date_initial') as HTMLSelectElement).value;
    const monto = (document.querySelector('#monto') as HTMLSelectElement).value;
    const frecuency = (document.querySelector('#frecuency') as HTMLSelectElement).value;
    const cuotas = (document.querySelector('#cuotas') as HTMLSelectElement).value;
    const value_total = (document.querySelector('#value_total') as HTMLSelectElement).value;
    const porcentaje =  (document.querySelector('#percentage') as HTMLSelectElement).value;
    const id_client_after = this.selectMenuPass2

    if(!cliente_id){
      button_guardar.style.display = "block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }
    const response_prestamos = await fetch(this.url.Ms1 + "get_loans_by_id/"+ cliente_id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    });
    const prestamos_total = await response_prestamos.json()
    const loansInActiveState = prestamos_total.filter((loan: any) => loan.state === 'Activo');
    let totalValue_prestamo = loansInActiveState.reduce((sum: any, loan: any) => sum + parseFloat(loan.value), 0);
    totalValue_prestamo = parseFloat(totalValue_prestamo) + parseFloat(monto)

    if(cuotas == '0'){
      button_guardar.style.display = "block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Cantidad de cuotas incorrecta',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }


    if (totalValue_prestamo > this.convertirACantidad(this.tope) && this.tope != "" && this.tope != "$0,00"){
      button_guardar.style.display = "block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Excede el tope de ' + this.tope,
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
      
    }

    let valors_1 = (document.querySelector('#monto') as HTMLSelectElement).value

    if (parseFloat(valors_1) > this.convertirACantidad(this.tope) && this.tope !="" && this.tope != "$0,00"){
      button_guardar.style.display = "block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error!',
        text: 'Valor mayor a lo disponible',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',

      })
      return
    }


    if (!isNaN(parseFloat(monto)) || isNaN(parseFloat(porcentaje))  ) {
      console.log("El valor es numérico");
    } else if (typeof monto === 'string' && typeof porcentaje === 'string') {
      button_guardar.style.display = "block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Solo ingresar numeros',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    } 

    if (!monto || !frecuency || !cuotas || !porcentaje) {
      button_guardar.style.display = "block";
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }

    if(parseInt(porcentaje) <= 0){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Valor invalido en porcentaje',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      });
      return;
    }


    let date_final = ""
    if (frecuency == "Mensual"){
      const total_days = (30 * parseInt(cuotas))
      const initialDate = new Date(date_initial.split('/').reverse().join('/'));
      const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
      date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
    }
    if (frecuency == "Quincenal"){
      const total_days = (15 * parseInt(cuotas))
      const initialDate = new Date(date_initial.split('/').reverse().join('/'));
      const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
      date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
    }
    if (frecuency == "Semanal"){
      const total_days = (7 * parseInt(cuotas))
      const initialDate = new Date(date_initial.split('/').reverse().join('/'));
      const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
      date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
    }
    if (frecuency == "Diario"){
      const total_days = (1 * parseInt(cuotas))
      const initialDate = new Date(date_initial.split('/').reverse().join('/'));
      const addedDate = new Date(initialDate.getTime() + total_days * 24 * 60 * 60 * 1000);
      date_final = `${addedDate.getDate().toString().padStart(2, '0')}/${(addedDate.getMonth() + 1).toString().padStart(2, '0')}/${addedDate.getFullYear()}`;
    }
 
    const data = {
      start_date: date_initial,
      expiry_date: date_final,
      value: parseFloat(monto).toFixed(2),
      fee_payment: parseFloat(value_total).toFixed(2),
      total_installments: cuotas,
      frequency: frecuency,
      porcentaje: porcentaje,
      customers_id: cliente_id,
      routes_id: ruta,
      cash_id: cash_id,
      latitude: this.lat,
      longitude: this.long,
      user_id: user_id
    };

    const updatedData = {
      ...data,
      ...(id_client_after ? { id_client_after } : {})
    };


    try {
      const response = await fetch(this.url.Ms1 + "prestamo", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(updatedData)
      });

      if (response.ok) {
        if(this.status_agendar){
          try {
            const response = await fetch(this.url.Ms1 + "agendar/" + this.id_agendar, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              },
            });
            if (response.ok) {
              console.log("a")
            }
          }catch (error) {
            console.error('Error al enviar el formulario', error);
          }
        }
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Enviado!',
          text: 'Prestamo Creado',
          showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await ChangeViewLoans(this.options_seelec)
            window.location.reload()
          }
          else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'error!',
              text: 'No se pude crear el cliente',
              showConfirmButton: true,
            })
            button_guardar.style.display = "block";
          }
        })
      }
      
    } catch (error) {
      button_guardar.style.display = "block";
      console.error('Error al enviar el formulario', error);
    }
  
  }

  value_cuota(){
    let monto = parseFloat((document.querySelector('#monto') as HTMLSelectElement).value);
    let cuotas = parseFloat((document.querySelector('#cuotas') as HTMLSelectElement).value);
    let porcentaje = parseFloat((document.querySelector('#percentage') as HTMLInputElement).value);
    let porcentaje_calucado = (porcentaje / 100)
    let value_total = (((monto * porcentaje_calucado) + monto) / cuotas).toFixed(2);
    if (parseInt((document.querySelector('#monto') as HTMLSelectElement).value) > parseInt(this.tope) && this.tope!=""){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error!',
        text: 'Valor mayor a lo disponible',
        showConfirmButton: true,  // Mostrar el botón "OK"
        confirmButtonText: 'OK',  // Texto del botón "OK"
        allowOutsideClick: false, // Evitar el clic fuera del cuadro
        allowEscapeKey: false,    // Evitar la tecla "esc"
        confirmButtonColor: '#0e658e',
      })
    }
    if(!monto || !cuotas || !porcentaje){
      (document.querySelector('#value_total') as HTMLSelectElement).value = '0';
    }
    else{

      (document.querySelector('#value_total') as HTMLSelectElement).value = value_total;
    }
  }

 
  search(){
    let searhcs = (document.querySelector('#search_inpt') as HTMLInputElement).value
    this.searchQuery = searhcs;
  }

  search2(){
    let searhcs = (document.querySelector('#search_inpt2') as HTMLInputElement).value
    this.searchQuery2 = searhcs;
  }
  MostarSelect2(){
    if(this.showMenus2 == false){
      this.showMenus2 = true
    }
    else{
      this.showMenus2 = false
    }
  }
  
  MostarSelect(){
    if(this.showMenus == false){
      this.showMenus = true
    }
    else{
      this.showMenus = false
    }
  }

  modalidad_info: any[] = [];
 async getModalidad(id:string): Promise<any>{
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "loan_mode/" + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        "ngrok-skip-browser-warning": "69420"
      },
    });
    const frecuency = document.getElementById('frecuency') as HTMLInputElement
    const porcentaje =  (document.getElementById('percentage') as HTMLSelectElement)
      if (response.ok) {
        const jsonResponse = await response.json();
        
        if('error' in jsonResponse){
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Faltan imagenes!',
              text: 'Cliente sin imagenes',
              showConfirmButton: true,
            })
            return
          
        }

        if ('status' in jsonResponse) {
          this.percentaje = ''
          frecuency.value = ''          
          this.cantidadOpciones = 62
          frecuency.disabled = false;
          porcentaje.disabled = false;
          frecuency.classList.remove("disable_form")
        }
        else{
          this.modalidad_info = jsonResponse
          this.frecuency_data = jsonResponse.frequency
          this.percentaje = jsonResponse.percentage
          this.cantidadOpciones = jsonResponse.quota;
          this.tope = this.formatNumber(jsonResponse.limit_loan)
          frecuency.value = jsonResponse.frequency
          frecuency.disabled = true;
          porcentaje.disabled = true;
          frecuency.classList.add("disable_form")
        }
        return this.modalidad_info;
      
    }else{
      this.percentaje = ''
      frecuency.value = ''     
      this.tope = ''     
      this.cantidadOpciones = 62
      frecuency.disabled = false;
      porcentaje.disabled = false;
      frecuency.classList.remove("disable_form")
      
    }
  }

  formatNumber(number:any){
    let data = parseFloat(number);
    let formattedNumber = data.toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    });
    return formattedNumber?.startsWith("-") ? "-$" + formattedNumber.slice(1) : "$" + formattedNumber;
  }


  convertirACantidad(n: any) {
    n = n.replace(/[\$\.]/g, '');
    n = n.replace(',', '.');
    return parseFloat(n);
  }


  async selectOption(item: any) {
    this.selectedOption = item.name;
    this.selectedMenus = item;
    this.selectMenuPass = item.id;
    this.showMenus = false
    await this.getModalidad(this.selectMenuPass)
  }

  async selectOption2(item: any) {
    this.showMenus2 = false
    this.selectedMenus2 = item;
    this.selectedOption2 = item.customers_id.name + " $"+ item.value  + " - Prestamo # " +item.id;
    this.selectMenuPass2 = item.id
  }

  async loansRuta(){
    const token = this.getCookie("_token")
    const id = this.getCookie("_route")
    const response = await fetch(this.url.Ms1 + "prestamo/ruta/" + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
      body:JSON.stringify({
        route_id: id,
        page: 1, 
        quantity: 600
      })
    })
    this.loans = await response.json()
  }

  createRange(n: number): number[] {
    return Array.from({ length: n }, (_, index) => index);
  }
 
}
