import { Component, EventEmitter, OnInit, Output, ComponentFactoryResolver, ViewContainerRef,ComponentRef  } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { faTrashAlt, faPencilAlt, faWrench, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { Url, Permission } from '../service/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormUsuarioComponent } from '../form-usuario/form-usuario.component'

@Component({
  selector: 'app-list-usuario',
  templateUrl: './list-usuario.component.html',
  styleUrls: ['./list-usuario.component.css']
})
export class ListUsuarioComponent {
  constructor(
    private router: Router,
    public url: Url,
    private componentFactoryResolver: ComponentFactoryResolver, 
    private viewContainerRef: ViewContainerRef) {}
  faCircleInfo = faCircleInfo;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  faWrench = faWrench;
  permissions: any ={}
  ngOnInit(): void {
    this.getUsers();
    this.permissions = Permission.ElementPermissionObjects()
  }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  addUser(){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormUsuarioComponent);
    const componentRef: ComponentRef<FormUsuarioComponent> = this.viewContainerRef.createComponent(componentFactory);

    Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      html:componentRef.location.nativeElement
    })

  }
  
  datos: any[] = [];
  
  async getUsers(): Promise<any> {
    const token = this.getCookie("_token")
     const response = await fetch(this.url.Ms1 + "users", {
         method: 'GET',
         headers: {
           "Authorization": `Bearer ${token}`,
           "ngrok-skip-browser-warning": "69420"
         },
       });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         return this.datos;
     }
    };

  
  
  toggleRow(index: number): void {
    this.collapsedRows[index] = !this.collapsedRows[index];
  }
    
  collapsedRows: boolean[] = new Array(this.datos.length).fill(true);

  borrar( dato: any ){
    const token = this.getCookie("_token")
    Swal.fire({
      title: 'Seguro quieres eliminar a \n',
      text: dato.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(this.url.Ms1 + "users/" + dato.id, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
        })
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Eliminado \n',
            text: dato.nombre,
            showConfirmButton: false,
            timer: 1800
          })
          window.location.reload();
        }
       
      }
    })
  }

  actualizar(dato: any) {
    // Enviamos el dato a la siguiente vista
    const navigationExtras: NavigationExtras = {
      queryParams: { dato: JSON.stringify(dato) }
    };
    this.router.navigate(['form-usuario'], navigationExtras);
  }

  update_pass(id: any){
    const token =  this.getCookie('_token');
    const htmlContent = `
    <h1>Cambiar contraseña</h1>
    <div style="display: grid;">
        <label for="password">Contraseña</label>
        <input id="password" class="form-control input-form" type="password">
        <label for="confirmPassword">Validar contraseña</label>
        <input id="confirmPassword" class="form-control input-form" type="password">
    </div>`;
    Swal.fire({
      html: htmlContent,
      showCloseButton: true,
      preConfirm: async () => {
          const password = (document.getElementById('password') as HTMLInputElement).value;
          const confirmPassword = (document.getElementById('confirmPassword') as HTMLInputElement).value;
          
          if(!password || !confirmPassword){
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Llena todos los campos...',
              showConfirmButton: false,

            });
            return;
          }


          if (password === confirmPassword) {
              // Los valores son iguales, realizar el fetch o la lógica que necesites
              try {
                  // Ejemplo de fetch (reemplaza esto con tu lógica)
                  const response = await fetch( this.url.Ms1 + 'update_password', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                           'Authorization': 'Bearer ' + token
                      },
                      body: JSON.stringify({
                          user_id: id,
                          password: password
                          
                      })
                  });
                  const data = await response.json();
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Contraseña cambiada',
                    showConfirmButton: true,  // Mostrar el botón "OK"
                    confirmButtonText: 'OK',  // Texto del botón "OK"
                    allowOutsideClick: false, // Evitar el clic fuera del cuadro
                    allowEscapeKey: false,    // Evitar la tecla "esc"
                    confirmButtonColor: '#0e658e',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload()
                    }
              })
              } catch (error) {
                  console.error('Error:', error);
              }
          } else {
              // Los valores no son iguales, puedes mostrar un mensaje de error o manejarlo de otra manera
              Swal.showValidationMessage('Las contraseñas no coinciden');
          }
      },
    });
    
    // this.router.navigate(['update_pasword', id]);
  }

  message(){
    this.router.navigate(['form-message']);
  }
}

