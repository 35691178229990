

<div class="menu-container" style="position: relative; height: 85vh;">
  <div id="conten_loads">
    <div class="spinner">
      <div></div>   
      <div></div>    
      <div></div>    
      <div></div>    
      <div></div>    
      <div></div>    
      <div></div>    
      <div></div>    
      <div></div>    
      <div></div>    
    </div>
  </div>
  
  
<app-menu-super-user></app-menu-super-user>
  <div id="infor_real" style="position: absolute; top: -9999px; left: -9999px; opacity: 0; width: 100%; height: 100%;"></div>
    <div class="list-sucursa" >
      <div style="background-color:#e4eaee;position: fixed;left: 9px;width: 96%;padding-bottom: 19px;z-index: 999;">
        <label style="text-align: center;display: block; font-size: 20px;" for="select-search">Buscar Mis</label>
        <div style="display: flex;">
          <select id="select-search" class="form-control input-form" [(ngModel)]="selectedOption" (change)="onSelectChange(selectedOption)">
            <option value="Clientes del dia" *ngIf="!permissions.hasOwnProperty('option_cliente_day_ver')" >Clientes del dia</option>
            <option value="Ventas del dia" >Prestamos del dia</option>
            <option value="Pagos del dia" >Pagos</option>
            <option value="Clientes de la ruta" >Ruta</option>
          </select>
          <input class="form-control input-form" type="text" id="buscar_sales" placeholder="Buscar" (input)="filterList($event)">
        </div>
   
      </div>




      <div *ngIf="!filter_data" style="top: 80px;margin-bottom: 160px;position: relative;">
        <ng-container *ngFor="let item of list_filter_sale" >
          <div class="contaner-princi">
            <div  *ngIf="selectedOption === 'Clientes del dia' && item.prestamosClientesDelDia && item.prestamosClientesDelDia[0]['daily']">
              <div *ngFor="let dato of item.prestamosClientesDelDia[0]['daily']" class="contaner-princi" [attr.id]="'content_activos-' + dato.id">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}"  style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                              {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp" style="width: 32px;margin-right: 10px; position: relative; top: 3px;">
                                      <a style="flex-shrink: 0;" href="tel:65998466722">
                                        <img src="../../assets/images/telefono.svg"alt="Teléfono" style="width: 28px;">
                                      </a>
                                      <a (click)="GpsLoan(dato)">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                          style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                          <g>
                                            <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                            <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                            <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                            <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                            <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                            <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                            <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                            <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                            <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                          </g>
                                        </svg>
                                      </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                          <div style="display: block; overflow: hidden;overflow-x: auto;">

                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                                <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                                <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail, dato.customers_id.name,dato)">Pagar</button>
                              </div>
                              <div  style="border-right: solid 2px #adb5bd59; height: 32px;">
                              </div>
  
                            </div>
  
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">
                                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                                </span>
                              </div>
                            </div>
                            <div class="row" style="text-align: center; display: table-cell;">
                              <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                              <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                                <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                  <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                                </div>
                                <div style="border-right: solid 2px #adb5bd59; height: 32px;">
                                  <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                                </div>
                              </ng-container>
                            
                            </div>
                            
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style=" height: 16px;">
                                <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                              </div>
                              <div>
                                <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                              </div>
                            </div>
                           
                         
                          </div>
                      </div>
                      <div style="right: 50%; position: absolute; left: 46%;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                      <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                        <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                        <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                       <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                       <p>
                         <b>Tope</b> 
                         <br>
                         {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                     </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>


            <div  *ngIf="selectedOption === 'Clientes del dia' && item.prestamosClientesDelDia && item.prestamosClientesDelDia[0]['payment_last']">
              <label style="text-align: center;display: block; font-size: 20px; margin-top: 10px;" for="select-search">
               Clientes para mas tarde             
              </label>
              <div *ngFor="let dato of item.prestamosClientesDelDia[0]['payment_last']" class="contaner-princi" [attr.id]="'content_activos-' + dato.id">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}"  style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                              {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                                          style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                                          href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                              alt="Teléfono" style="width: 28px;"></a>
                                              <a (click)="GpsLoan(dato)">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                                  style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                                  <g>
                                                    <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                                    <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                                    <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                                    <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                                    <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                                    <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                                    <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                                    <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                    <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                  </g>
                                                </svg>
                                              </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                          <div style="display: block; overflow: hidden;overflow-x: auto;">

                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                                <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                                <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                              </div>
                              <div  style="border-right: solid 2px #adb5bd59; height: 32px;">
                              </div>
  
                            </div>
  
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">
                                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                                </span>
                              </div>
                            </div>
                            <div class="row" style="text-align: center; display: table-cell;">
                              <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                              <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                                <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                  <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                                </div>
                                <div style="border-right: solid 2px #adb5bd59; height: 32px;">
                                  <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                                </div>
                              </ng-container>
                            
                            </div>
                            
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style=" height: 16px;">
                                <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                              </div>
                              <div>
                                <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                              </div>
                            </div>
                           
                         
                          </div>
                      </div>
                      <div style="right: 50%; position: absolute; left: 46%;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                      <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                        <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                        <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                       <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                       <p>
                         <b>Tope</b> 
                         <br>
                         {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                     </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            
            <div *ngIf="selectedOption === 'Pagos del dia' && item.prestamosPagosDelDia && item.prestamosPagosDelDia['cash_payments']">
              <label style="text-align: center;display: block; font-size: 20px; margin-top: 10px;" for="select-search">
                Pago Efectivos
                <b>$ {{item.prestamosPagosDelDia.total_cash}}</b>
              
              </label>
              <div class="contaner-princi" *ngFor="let dato of item.prestamosPagosDelDia['cash_payments']">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}" style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                              {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                                          style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                                          href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                              alt="Teléfono" style="width: 28px;"></a>
                                              <a (click)="GpsLoan(dato)">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                                  style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                                  <g>
                                                    <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                                    <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                                    <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                                    <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                                    <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                                    <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                                    <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                                    <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                    <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                  </g>
                                                </svg>
                                              </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                          <div style="display: block; overflow: hidden;overflow-x: auto;">

                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                                <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                                <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                              </div>
                              <div  style="border-right: solid 2px #adb5bd59; height: 32px;">
                              </div>
  
                            </div>
  
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">
                                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                                </span>
                              </div>
                            </div>
                            <div class="row" style="text-align: center; display: table-cell;">
                              <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                              <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                                <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                  <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                                </div>
                                <div style="border-right: solid 2px #adb5bd59; height: 32px;">
                                  <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                                </div>
                              </ng-container>
                            
                            </div>
                            
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style=" height: 16px;">
                                <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                              </div>
                              <div>
                                <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                              </div>
                            </div>
                           
                         
                          </div>
                        
                      </div>
                      <div style="right: 50%; position: absolute; left: 46%;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                      <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                        <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                        <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                       <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                       <p>
                         <b>Tope</b> 
                         <br>
                         {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                     </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>

            <div *ngIf="selectedOption === 'Pagos del dia' && item.prestamosPagosDelDia && item.prestamosPagosDelDia['transfer_payments']">
              <label style="text-align: center;display: block; font-size: 20px;" for="select-search">
                Pago Transferencia
                <b>$ {{item.prestamosPagosDelDia.total_transfer}}</b>
              </label>
            
              <div class="contaner-princi" *ngFor="let dato of item.prestamosPagosDelDia['transfer_payments']">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}"  style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                              {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                                          style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                                          href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                              alt="Teléfono" style="width: 28px;"></a>
                                              <a (click)="GpsLoan(dato)">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                                  style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                                  <g>
                                                    <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                                    <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                                    <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                                    <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                                    <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                                    <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                                    <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                                    <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                    <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                  </g>
                                                </svg>
                                              </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                          <div style="display: block; overflow: hidden;overflow-x: auto;">

                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                                <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                                <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                              </div>
                              <div  style="border-right: solid 2px #adb5bd59; height: 32px;">
                              </div>
  
                            </div>
  
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">
                                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                                </span>
                              </div>
                            </div>
                            <div class="row" style="text-align: center; display: table-cell;">
                              <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                              <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                                <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                  <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                                </div>
                                <div style="border-right: solid 2px #adb5bd59; height: 32px;">
                                  <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                                </div>
                              </ng-container>
                            
                            </div>
                            
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style=" height: 16px;">
                                <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                              </div>
                              <div>
                                <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                              </div>
                            </div>
                           
                         
                          </div>
                        
                      </div>
                      <div style="right: 50%; position: absolute; left: 46%;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                      <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                        <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                        <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                       <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                       <p>
                         <b>Tope</b> 
                         <br>
                         {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                     </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
  
            <div *ngIf="selectedOption === 'Clientes de la ruta'  && item.datos" id="containers_cards" >
              <div [attr.data-loan]="dato.id"  [attr.data-order]="dato.order" class="contaner-princi card_ruta" *ngFor="let dato of item.datos">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}"  style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                            {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                              
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                                          style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                                          href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                              alt="Teléfono" style="width: 28px;"></a>
                                              <a (click)="GpsLoan(dato)">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                                  style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                                  <g>
                                                    <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                                    <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                                    <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                                    <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                                    <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                                    <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                                    <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                                    <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                    <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                  </g>
                                                </svg>
                                              </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                        <div style="display: block; overflow: hidden;overflow-x: auto;">

                          <div class="row" style="text-align: center; display: table-cell;">
                            <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                              <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                              <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                              <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                            </div>
                            <div  style="border-right: solid 2px #adb5bd59; height: 35px;">
                            </div>

                          </div>

                          <div class="row" style="text-align: center; display: table-cell;">
                            <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                              <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                            </div>
                            <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                              <span style="font-size: 16px; font-weight: 600; display: block;">
                                {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                              </span>
                            </div>
                          </div>
                          <div class="row" style="text-align: center; display: table-cell;">
                            <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                            <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                              </div>
                            </ng-container>
                          
                          </div>
                          
                          <div class="row" style="text-align: center; display: table-cell;">
                            <div style=" height: 16px;">
                              <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                            </div>
                            <div>
                              <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                            </div>
                          </div>
                         
                       
                        </div>
                        
                      </div>
                      <div style="right: 50%; position: relative;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                    <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                       <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                       <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                      <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                      <p>
                        <b>Tope</b> 
                        <br>
                        {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                    </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            
            <div *ngIf="selectedOption === 'Ventas del dia'  && item.sale_day">
              <div class="contaner-princi" *ngFor="let dato of item.sale_day">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}"  style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                              {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                                          style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                                          href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                              alt="Teléfono" style="width: 28px;"></a>
                                              <a (click)="GpsLoan(dato)">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                                  style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                                  <g>
                                                    <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                                    <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                                    <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                                    <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                                    <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                                    <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                                    <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                                    <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                    <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                  </g>
                                                </svg>
                                              </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                          <div style="display: block; overflow: hidden;overflow-x: auto;">

                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                                <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                                <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                              </div>
                              <div  style="border-right: solid 2px #adb5bd59; height: 32px;">
                              </div>
  
                            </div>
  
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">
                                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                                </span>
                              </div>
                            </div>
                            <div class="row" style="text-align: center; display: table-cell;">
                              <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                              <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                                <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                  <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                                </div>
                                <div style="border-right: solid 2px #adb5bd59; height: 32px;">
                                  <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                                </div>
                              </ng-container>
                            
                            </div>
                            
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style=" height: 16px;">
                                <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                              </div>
                              <div>
                                <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                              </div>
                            </div>
                           
                         
                          </div>
                        
                      </div>
                      <div style="right: 50%; position: absolute; left: 46%;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                      <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                        <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                        <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                       <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                       <p>
                         <b>Tope</b> 
                         <br>
                         {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                     </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>

            <!-- venta refinanciadas -->
            <div *ngIf="selectedOption === 'Ventas del dia'  && item.refinance_day">
              <label style="text-align: center;display: block; font-size: 20px;" for="select-search">
                Ventas refinanciadas
                <!-- <b>$ {{item.prestamosPagosDelDia.total_transfer}}</b> -->
              </label>
            

              <div class="contaner-princi" *ngFor="let dato of item.refinance_day">
                <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
                  <div class="contener-datas" >
                      <div style="width: 100%; text-align: start;">
                        <div class="content-mator-prin" >
                          <div class="conten-names-info" style="display: flex;">
                            <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}"  style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;"  (click)="UpdtaeClientModal(dato)">
                              {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                            </label>
                          </div>
                        </div>
                        <div class="content-mator-prin" style="
                              height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                              <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                                <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                                 [ngStyle]="{'background-color': infos(dato).color }">
                                  <span class="tooltip-text">  
                                    {{infos(dato).message}}
                                  </span>
                                    {{infos(dato).alerta}}
                                </div>
                                  <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                                      <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                                          style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                                          href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                              alt="Teléfono" style="width: 28px;"></a>
                                              <a (click)="GpsLoan(dato)">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                                  style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                                  <g>
                                                    <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                                    <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                                    <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                                    <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                                    <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                                    <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                                    <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                                    <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                    <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                                  </g>
                                                </svg>
                                              </a>
                                  </div>
                                  <div style="margin-left: 50px;">
                                    <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                                  </div>
                              </div>
                          </div>
                          <div style="display: block; overflow: hidden;overflow-x: auto;">

                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                                <span style="font-weight: 700;font-size: 15px;display: block;">{{dato.percentage}}%</span>
                                <button style="margin-top: -12px;" class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                              </div>
                              <div  style="border-right: solid 2px #adb5bd59; height: 32px;">
                              </div>
  
                            </div>
  
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                              </div>
                              <div style="border-right: solid 2px #adb5bd59; height: 35px;">
                                <span style="font-size: 16px; font-weight: 600; display: block;">
                                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                                </span>
                              </div>
                            </div>
                            <div class="row" style="text-align: center; display: table-cell;">
                              <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                              <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                                <div style="border-right: solid 2px #adb5bd59; height: 16px;">
                                  <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                                </div>
                                <div style="border-right: solid 2px #adb5bd59; height: 32px;">
                                  <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                                </div>
                              </ng-container>
                            
                            </div>
                            
                            <div class="row" style="text-align: center; display: table-cell;">
                              <div style=" height: 16px;">
                                <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                              </div>
                              <div>
                                <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                              </div>
                            </div>
                           
                         
                          </div>
                        
                      </div>
                      <div style="right: 50%; position: absolute; left: 46%;">
                        <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                      </i>
                       
                      </div>
                      
                  </div>
                  <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
                    <div class="info-loanClient">
                      <div style="width: 30%;">
                        <label for="" style="font-size: 14px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                            Fecha Vence
                          </span>
                          <br>
                          {{dato.expiry_date|date:"dd MMM yy"}}
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Cuotas Pagas
                          </span>
                          <br>
                          {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                        </label>
                        <label  for="" style="font-size: 14px; border-top: solid 1px;">
                          <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                            Dias En Mora
                          </span>
                          <br>
                          {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                        </label>
                      </div>
                      <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                        <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                        <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                       <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                       <p>
                         <b>Tope</b> 
                         <br>
                         {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
                     </div>
                    <div style="width: 30%; padding-top: 5px; display: block;">
                      <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                      <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                      <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
                    </div>
                    </div>
                    <div>
                      Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
              
          </div>
        </ng-container>
      </div>
    
      <div *ngIf="filter_data" style="top: 80px;margin-bottom: 160px;position: relative;">
        <ng-container *ngFor="let item of list_filter_sale" >
          <div class="contaner-princi" *ngFor="let dato of item.datos">
            <div class="contaner-list"  [ngClass]="{'list_info': selectedItems.includes(dato.id)}">
              <div class="contener-datas" >
                <div style="width: 100%; text-align: start;">
                  <div class="content-mator-prin" >
                    <div class="conten-names-info" style="display: flex;">
                      <label for="" [ngClass]="{'revision-text':dato.revision === 'True', 'info-client': true}" style="width: 100%; display: block; overflow-x: scroll; white-space: nowrap;" (click)="UpdtaeClientModal(dato)">
                        {{dato.customers_id.name}} <b>/</b> {{dato.customers_id.customer_detail_id.surname}}
                      </label>
                    </div>
                  </div>
                  <div class="content-mator-prin" style="
                  height: 38px !important; overflow-x: auto; overflow-y: hidden;">
                  <div class="conten-names-info" style="margin-left: 20px;display: flex;"> 
                  
                    <div  class="btn-payments bnt-loans tooltip-container" style="padding-right: 13px;padding-left: 13px;margin-top: 6px;border: none;border-radius: 29px;color: #fff;height: 23px;font-size: 14px;"
                     [ngStyle]="{'background-color': infos(dato).color }">
                      <span class="tooltip-text">  
                        {{infos(dato).message}}
                      </span>
                        {{infos(dato).alerta}}
                    </div>
                      <div style="bottom: 8px;position: relative;display: flex;justify-content: end;align-items: center;left: 24px;/* stroke-linecap: butt; */">
                          <img src="../../assets/images/whatsapp.svg" alt="WhatsApp"
                              style="width: 32px;margin-right: 10px; position: relative; top: 3px;"><a style="flex-shrink: 0;"
                              href="tel:65998466722"><img src="../../assets/images/telefono.svg"
                                  alt="Teléfono" style="width: 28px;"></a>
                                  <a (click)="GpsLoan(dato)">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 64 64" 
                                      style="width: 40px;height: 29px;" xml:space="preserve" class="">
                                      <g>
                                        <path fill="#6bc13b" d="M48.435 26a5 5 0 0 1 4.727 3.37l8.51 24.68a5.995 5.995 0 0 1-5.667 7.949H7.995a5.995 5.995 0 0 1-5.667-7.949l8.51-24.68A5 5 0 0 1 15.565 26z" opacity="1" data-original="#6bc13b"></path>
                                        <path fill="#7bdf45" d="M53.162 29.37A5 5 0 0 0 48.435 26H18.621l-8.293 24.05a5.995 5.995 0 0 0 5.667 7.949h45.658A5.97 5.97 0 0 0 62 56.004c0-.665-.111-1.326-.328-1.954z" opacity="1" data-original="#7bdf45"></path>
                                        <path fill="#474747" d="M32 62 7.897 37.897l2.308-6.692L41 62z" opacity="1" data-original="#474747"></path>
                                        <path fill="#686868" d="M55.085 34.946 30 51l4.439 4.439L57.141 40.91z" opacity="1" data-original="#686868"></path>
                                        <path fill="#d23f34" d="m32 38 10.821-13.14A13.936 13.936 0 0 0 46 16c0-7.732-6.268-14-14-14S18 8.268 18 16a13.94 13.94 0 0 0 3.179 8.86z" opacity="1" data-original="#d23f34" class=""></path>
                                        <circle cx="32" cy="16" r="8" fill="#ffffff" opacity="1" data-original="#ffffff" class=""></circle>
                                        <path fill="#686868" d="M12.82 42.82 28 58h9L15.128 36.128z" opacity="1" data-original="#686868"></path>
                                        <path d="m62.618 53.724-8.511-24.68A6.001 6.001 0 0 0 48.435 25h-4.47a14.96 14.96 0 0 0 3.034-9c0-8.271-6.729-15-15-15s-15 6.729-15 15c0 3.248 1.081 6.415 3.034 9h-4.47a6.001 6.001 0 0 0-5.672 4.044l-8.509 24.68A6.996 6.996 0 0 0 1 56.005 7.003 7.003 0 0 0 7.995 63h48.01A7.003 7.003 0 0 0 63 56.005c0-.778-.129-1.546-.382-2.281zM38.586 61h-6.172L9.046 37.632l1.583-4.589zm-7-9.828 22.968-14.699 1.386 4.019-21.362 13.672zM32 3c7.168 0 13 5.832 13 13 0 2.992-1.048 5.913-2.95 8.224L32 36.427 21.95 24.224A12.972 12.972 0 0 1 19 16c0-7.168 5.832-13 13-13zM11.783 29.696A4 4 0 0 1 15.564 27h6.082l9.582 11.636a1 1 0 0 0 1.542 0L42.352 27h6.082a4 4 0 0 1 3.781 2.696l1.666 4.832-23.744 15.196L11.354 30.94zM3 56.005c0-.557.092-1.104.272-1.629l5.049-14.641L29.586 61H7.995A5.001 5.001 0 0 1 3 56.005zM56.005 61H41.414l-5.389-5.389L56.61 42.436l4.117 11.94A5.001 5.001 0 0 1 56.004 61z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                        <path d="M32 25c4.963 0 9-4.037 9-9s-4.037-9-9-9-9 4.037-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.141 7 7s-3.141 7-7 7-7-3.141-7-7 3.141-7 7-7z" fill="#000000" opacity="1" data-original="#000000" class=""></path>
                                      </g>
                                    </svg>
                                  </a>
                      </div>
                      <div style="margin-left: 50px;">
                        <button *ngIf="revision == true" class="bnts-verifik" (click)="VerifikClient(dato)">Verificar</button>
                      </div>
                  </div>
              </div>
            <div style="display: block; overflow: hidden;overflow-x: auto;">

              <div class="row" style="text-align: center; display: table-cell;">
                <div style="border-right: solid 2px #adb5bd59; height: 20px;">
                  <div class="contenInvisible" *ngIf="permissions.hasOwnProperty('btn_pagos_create')"></div>
                  <button class="btn-agend bnt-loans-pago" *ngIf="!permissions.hasOwnProperty('btn_pagos_create')" (click)="Payment(dato.id, dato.fee_payment, dato.loan_details_id.missing_installments,dato.alert_payment_detail,dato.customers_id.name,dato)">Pagar</button>
                </div>
                <div  style="border-right: solid 2px #adb5bd59; height: 20px;">
                </div>

              </div>

              <div class="row" style="text-align: center; display: table-cell;">
                <div style="border-right: solid 2px #adb5bd59; height: 20px;">
                  <p style="font-size: 15px;">{{dateFrecuency(dato)}}</p>
                </div>
                <span style="font-size: 16px; font-weight: 600; display: block;">
                  {{ (dato.frequency === "Quincenal" || dato.frequency === "Mensual") ? dato.frequency : 'Frecuencia' }}
                </span>
              </div>
              <div class="row" style="text-align: center; display: table-cell;">
                <!-- Este bloque se mostrará si positive_balance es positivo o cero -->
                <ng-container *ngIf="dato.loan_details_id.positive_balance >= 0">
                  <div style="border-right: solid 2px #adb5bd59; height: 20px;">
                    <p style="font-size: 15px;">${{ dato.loan_details_id.outstanding_balance | number:'1.2-2' }}</p>
                  </div>
                  <div style="border-right: solid 2px #adb5bd59; height: 20px;">
                    <span style="font-size: 16px; font-weight: 600; display: block;">Saldo</span>
                  </div>
                </ng-container>
              
              </div>
              
              <div class="row" style="text-align: center; display: table-cell;">
                <div style=" height: 20px;">
                  <p style="font-size: 15px;">${{dato.fee_payment|number:'1.2-2'}}</p>
                </div>
                <div>
                  <span style="font-size: 16px; font-weight: 600; display: block;">Cuota</span>
                </div>
              </div>
             
           
            </div>
                  
                </div>
                <div style="right: 50%; position: absolute; left: 46%;">
                  <i class="back" (click)="verInfoLoan(dato)" [ngClass]="{'refinace_id': dato.refinance_id != null }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </i>
                 
                </div>
                
            </div>
            <div class="config-menu" *ngIf="dato.isConfigOpen" [ngClass]="{'show': dato.isConfigOpen}">
              <div class="info-loanClient">
                <div style="width: 30%;">
                  <label for="" style="font-size: 14px;">
                    <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -25px;">
                      Fecha Vence
                    </span>
                    <br>
                    {{dato.expiry_date|date:"dd MMM yy"}}
                  </label>
                  <label  for="" style="font-size: 14px; border-top: solid 1px;">
                    <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                      Cuotas Pagas
                    </span>
                    <br>
                    {{dato.total_installments - dato.loan_details_id.missing_installments }} / {{dato.total_installments}} 
                  </label>
                  <label  for="" style="font-size: 14px; border-top: solid 1px;">
                    <span style="font-weight: 600; font-size: 13px; display: block; margin-bottom: -26px;">
                      Dias En Mora
                    </span>
                    <br>
                    {{calcularDiferenciaEnDias(dato.possible_payment,dato.today,dato.frequency)}}
                  </label>
                </div>
                <div style="width: 40%; display: block; padding-top: 15px; margin-top: -20px;">
                 <label *ngIf="dato.refinance_id != null" for="" style="position: relative;top: 3px; font-weight: 600;">Refinanciado</label>
                 <label *ngIf="dato.refinance_id == null" for="" style="position: relative;top: 3px; font-weight: 700;">Monto</label>
                <label for="" class="label-btns">${{dato.value|number:'1.2-2' }}</label>
                <p>
                  <b>Tope</b> 
                  <br>
                  {{getFormattedCurrency(dato.customers_id.loan_mode_routes_id != null ? dato.customers_id.loan_mode_routes_id.limit_loan : '0')}}</p>
              </div>
              <div style="width: 30%; padding-top: 5px; display: block;">
                <button class="btn-payments bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_historico_ver')" (click)="modalCLient(dato,true)">Historico</button>
                <button class="btn-agend bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_agendar_create')" (click)="alert_payment(dato.id)">Agendar</button>
                <button class="btn-refina bnt-loans" *ngIf="!permissions.hasOwnProperty('btn_prestamos_refinance_create')"  (click)="refinanceLoan(dato.id)">Refinanciar</button>
              </div>
              </div>
              <div>
                Direccion: {{dato.customers_id.customer_detail_id.address}} / Telefono {{dato.customers_id.phone}}
              </div>
            </div>
             
            </div>
          </div>
        </ng-container>
       
      </div>


      <div class="contains-item-toggle fab-menu " *ngIf="!permissions.hasOwnProperty('btn_cliente_ver') || !permissions.hasOwnProperty('btn_cliente_create') || !permissions.hasOwnProperty('btn_revision_ver')" >
          <div class="circle-toggles" >
            <div>
              <button class="btns-agg-options" (click)="ClientList()"  *ngIf="!permissions.hasOwnProperty('btn_cliente_ver')" >
               Listar
              </button>
            </div>
            <div>
              <button class="btns-agg-options" (click)="ClientForm()" *ngIf="!permissions.hasOwnProperty('btn_cliente_create')">
                Crear
              </button>
             
            </div>
            <div>
              <button class="btns-agg-options" (click)="ListVerifik()" *ngIf="!permissions.hasOwnProperty('btn_revision_ver')">
                Verificación 
              </button>
            </div>
          </div>
      </div>
      <div class="contains-item-toggle fab-menu2" >
        <div class="circle-toggles">
       
          <div>
            <button class="btns-agg-options" (click)="AgendartList()">
             Ver
            </button>
          </div>
          <div>
            <button class="btns-agg-options" (click)="ListLoanCancel()">
              Cancelado
            </button>
           
          </div>
        </div>
    </div>
    <div class="contains-item-toggle fab-menu3" *ngIf="!permissions.hasOwnProperty('btn_movimientos_ver') || !permissions.hasOwnProperty('btn_movimientos_create')" >
      <div class="circle-toggles">
     
        <div>
          <button class="btns-agg-options" (click)="MovimientoList()" *ngIf="!permissions.hasOwnProperty('btn_movimientos_ver')">
           Listar
          </button>
        </div>
        <div>
          <button class="btns-agg-options" (click)="MovimientoForm()" *ngIf="!permissions.hasOwnProperty('btn_movimientos_create')">
            Crear
          </button>
         
        </div>
      </div>
  </div>


        <div class="menu-items-container">
          <div style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_movimientos_ver') || !permissions.hasOwnProperty('btn_movimientos_create')" >
            <button class="btns-agg fab-button-movimientos">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
                x="0" y="0" viewBox="0 0 451.847 451.847" style="margin-left: -1px;" xml:space="preserve" class="">
                <g transform="matrix(0.9999999999999996,0,0,0.9999999999999996,1.1368683772161603e-13,1.9895196601282805e-13)">
                  <path style="fill:antiquewhite;"
                    d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                    fill="#000000" opacity="1" data-original="#000000" class=""></path>
                </g>
              </svg>
            </button>
        
          </div>
          <div style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_cliente_ver') || !permissions.hasOwnProperty('btn_cliente_create') || !permissions.hasOwnProperty('btn_revision_ver')">
            <button class="btns-agg fab-button-client" >
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
                x="0" y="0" viewBox="0 0 451.847 451.847" style="margin-left: -1px;" xml:space="preserve" class="">
                <g transform="matrix(0.9999999999999996,0,0,0.9999999999999996,1.1368683772161603e-13,1.9895196601282805e-13)">
                  <path style="fill:antiquewhite;"
                    d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                    fill="#000000" opacity="1" data-original="#000000" class=""></path>
                </g>
              </svg>
            </button>
          
          </div>
          <div style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_prestamos_create')" >
            <button class="btns-agg" (click)="loansForm()"  >
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
              x="0" y="0" viewBox="0 0 451.847 451.847" style="margin-left: -1px;" xml:space="preserve" class="">
              <g transform="matrix(0.9999999999999996,0,0,0.9999999999999996,1.1368683772161603e-13,1.9895196601282805e-13)">
                <path style="fill:antiquewhite;"
                  d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                  fill="#000000" opacity="1" data-original="#000000" class=""></path>
              </g>
            </svg>
            </button>
           
          </div>
          <div style="margin: auto;">
            <button class="btns-agg fab-button-agendar" >
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17"
              x="0" y="0" viewBox="0 0 451.847 451.847" style="margin-left: -1px;" xml:space="preserve" class="">
              <g transform="matrix(0.9999999999999996,0,0,0.9999999999999996,1.1368683772161603e-13,1.9895196601282805e-13)">
                <path style="fill:antiquewhite;"
                  d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                  fill="#000000" opacity="1" data-original="#000000" class=""></path>
              </g>
            </svg>
        
            </button>
         
          </div>
          </div>
   
          <div style="display: flex; bottom: 0; left: 0; right: 0; position: fixed;">
            <div style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_movimientos_ver') || !permissions.hasOwnProperty('btn_movimientos_create')">
              <p class="label-btnsloan">Movimientos</p>
            </div>
            <div style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_cliente_ver') || !permissions.hasOwnProperty('btn_cliente_create') || !permissions.hasOwnProperty('btn_revision_ver')">
              <p class="label-btnsloan">Clientes</p>
            </div>
            <div style="margin: auto;" *ngIf="!permissions.hasOwnProperty('btn_prestamos_create')">
              <p class="label-btnsloan">Prestamo</p>
            </div>
            <div style="margin: auto;">
              <p class="label-btnsloan">Agendar</p>
            </div>
          </div>
    </div>
</div>
    
<router-outlet></router-outlet>
