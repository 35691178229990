import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { Url } from '../service/data.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-movimientos',
  templateUrl: './form-movimientos.component.html',
  styleUrls: ['./form-movimientos.component.css'],
})
export class FormMovimientosComponent  implements OnInit {
  selectedDates: Date;
  selectedFile: File | null = null;
  preview_1: string | ArrayBuffer | null = null;
  img_1 = false;
  submitting = false;
  valorMovimiento: number | null = null;
  valorSeleccionado: string = '';
  sucurssal_cash: boolean = false;
  constructor(public url: Url,  private location: Location,  private route: ActivatedRoute,private router: Router) {
    this.selectedDates = new Date();
   }

  ngOnInit() {
    const currentUrl = this.router.url;
    if (currentUrl.startsWith('/cash-branch/')) {
      this.sucurssal_cash = true
      this.getModeLoanSucursal()
    }
    else{
      this.sucurssal_cash= false;
      this.getModeLoan();
    }
  }


  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }


  datos_mode: any[] = [];
  async getModeLoan(): Promise<any> {
    const token = this.getCookie("_token")
    const ids = this.getCookie("_id")
    const brans = this.getCookie("_branch")
    const response = await fetch(this.url.Ms1 + "concepto_user/" + ids + "/" + brans, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_mode = jsonResponse;
         return this.datos_mode;
     }
   }

   async getModeLoanSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const ids = this.getCookie("_branch")
    const response = await fetch(this.url.Ms1 + "concepto_sucursal/" + ids, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_mode = jsonResponse;
         return this.datos_mode;
     }
   }



   previewImage(file: File, type: string): void {
    if (type == "recibo"){
      this.img_1 = true;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview_1 = e.target.result;
      };
      reader.readAsDataURL(file);
    }    
  }
  removeImg1(){
    this.img_1 = false;
  }
   
  frontal(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.previewImage(file, "recibo");
    }
  }
 

  async PostMovimiento(){
    if (!this.submitting) {
      this.submitting = true;
      
      const token = this.getCookie("_token")
      const routes_id =  this.getCookie("_route")
      const cash_id =  this.getCookie("_cash")
      const branch =  this.getCookie("_branch")
      let fecha = new Date();
      let dia = fecha.getDate().toString().padStart(2, '0');
      let mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      let año = fecha.getFullYear().toString();
      let dates =  `${año}-${mes}-${dia}`;
  
      const type_motion = (document.querySelector('#type_motion') as HTMLInputElement).value;
      const value_motion = (document.querySelector('#value_motion') as HTMLInputElement).value;
      const selectedOption = (document.querySelector('#type_motion') as HTMLSelectElement).options[(document.querySelector('#type_motion') as HTMLSelectElement).selectedIndex];
      const selectedType = selectedOption.getAttribute('data-type');
      let images1: any;
      if(this.sucurssal_cash == true && selectedType != "Egresos" && "Ingresos"){
         images1 = null
      }else{
        images1 = (document.getElementById("images1") as HTMLInputElement).files;
      }
  
      // Obtener el atributo data-type del elemento seleccionado
      
      let data: any;
      if(this.sucurssal_cash){
        data = {
          sucursal_id: branch,
          date: dates,
          value_motion: value_motion,
          type_motion: type_motion,
          selectedType: selectedType,
          cash_id: cash_id,
        };
      }else if(selectedType == "Transferir"){
          data = {
          routes_id: routes_id,
          sucursal_id: branch,
          date: dates,
          value_motion: value_motion,
          type_motion: type_motion,
          selectedType: selectedType,
          cash_id: cash_id,
        };
      }
      else{
        data = {
          routes_id: routes_id,
          date: dates,
          value_motion: value_motion,
          type_motion: type_motion,
          selectedType: selectedType,
          cash_id: cash_id,
        };
      }
    if(!type_motion || parseInt(value_motion) <= 0){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llene todos los campos',
        showConfirmButton: true,
        confirmButtonColor: '#0e658e',
      })
      return
    }
  
    if(selectedType != "Egresos" && "Ingresos"){
      console.log(".")
    }else{
      if(!images1?.length){
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Falta imagen del comprobante',
          showConfirmButton: true,
          confirmButtonColor: '#0e658e',
        })
        return
      }
    }
    
  
   
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      if (images1 && images1.length > 0) {
        formData.append('img1', images1[0]);
      }
  
      try {
        const response = await fetch(this.url.Ms1 + "movimiento", {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          },
          body: formData
        });
        if (response.ok) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Enviado!',
            text: 'Movimiento creado',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload()
            }
          })

        
        }
        else{
          let result = await response.json()
          if(result.error == "Movimiento mayor a saldo"){
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Error',
              text: 'Movimiento excede saldo disponible',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: '#FF0000',
            })
            return
          }
          else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Error',
              text: 'Movimiento excede el límite',
              showConfirmButton: true,
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: '#FF0000',
            })
            return
          }
         
        }
        
  
       } catch (error) {
          console.error('Error en la solicitud', error);
       }
    }
    
  
  }
   
  logModeId(mode: any) {
    console.log("mode.id:", mode.income_expense_configuration_id.id);
  }
}
