import { Component, OnInit,Renderer2 } from '@angular/core';
import { Url,Permission } from '../service/data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { faImage,faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormClienteComponent } from '../form-cliente/form-cliente.component';
import { FormLoansComponent } from '../form-loans/form-loans.component';
import { UpdateClientComponent } from '../update-client/update-client.component';
import { MapaLoanComponent } from '../mapa-loan/mapa-loan.component';


@Component({
  selector: 'app-list-client-ruta',
  templateUrl: './list-client-ruta.component.html',
  styleUrls: ['./list-client-ruta.component.css'],
})
export class ListClientRutaComponent  implements OnInit {
  tooltipStates: { [key: string]: boolean } = {};
  constructor( private renderer: Renderer2,public url: Url,private router: Router,private componentFactoryResolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef,private decimalPipe: DecimalPipe) { 
    this.tooltipStates['tooltip_5'] = false;
  }
  faImage = faImage;
  faPencilAlt = faPencilAlt
  filter_data = false;
  originalDatos: any[] = [];
  permissions: any ={}

  ngOnInit() {
    this.getUsers();
    this.permissions = Permission.ElementPermissionObjects()
  }
  Meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  

  formateaMiles(numero: any) {
    return this.decimalPipe.transform(numero, '1.2-2');
  }
  

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }

  mostarToltip(name:any,id:any,info:any=null){
    if(info != null && id != 2){
      if (typeof(info) == "string"){
        var tooltipName = `tooltip${name}_3`;
      }else{
        if(id == 1){
          var tooltipName = `tooltip${name}_6`;
        }else{
          var tooltipName = `tooltip${name}_2`;
        }
      }
      
    }
    else{
      var tooltipName = `tooltip${name}`;
    }
    
    const globalTooltips = document.querySelectorAll('.globalTooltip');
    globalTooltips.forEach(tooltip => {
      this.renderer.setStyle(tooltip, 'display', 'none');
    });

    
    this.tooltipStates[tooltipName] = !this.tooltipStates[tooltipName];
    if (!this.tooltipStates[tooltipName]) {
      this.realizarAccion(name,id,info);
    }
  }

  mostarToltip2(name:any,id:any,info:any=null){
    if(info != null && id != 2){
      if (typeof(info) == "string"){
        var tooltipName = `tooltip${name}_3`;
      }else{
        if(id == 1){
          var tooltipName = `tooltip${name}_6`;
        }else{
          var tooltipName = `tooltip${name}_2`;
        }
      }
      
    }
    else{
      var tooltipName = `tooltip${name}`;
    }
    
    const globalTooltips = document.querySelectorAll('.globalTooltip');
    globalTooltips.forEach(tooltip => {
      this.renderer.setStyle(tooltip, 'display', 'none');
    });

    
    this.tooltipStates[tooltipName] = !this.tooltipStates[tooltipName];
    if (!this.tooltipStates[tooltipName]) {
      this.AsingRutas(info.id)
    }
  }


  realizarAccion(name:any,id: any,info=null) {
    switch (id) {
      case 1:
        this.Create_loan(info);
        break;
      case 2:
        if(!this.permissions.hasOwnProperty('btn_cliente_ver_imagen')){
          this.verFotos(info)
          break;
        }
        else{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'No tiene permisos para ver imagenes',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          });
          break;
        }
      case 3:
          if(!this.permissions.hasOwnProperty('btn_cliente_ver_imagen')){
            this.actualizar(info)
            break;
          }else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'No tiene permisos para ver ediar cliente',
              showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
            });
            break;
          }
      case 4:
          this.FormModalidad(name)
          break;
      
      default:
        break;
    }
  }


  datos: any[] = [];
  async getUsers(): Promise<any> {
    const id = this.getCookie("_route")
    const token = this.getCookie("_token")
     const response = await fetch(this.url.Ms1 + "cliente/ruta/" + id, {
         method: 'GET',
         headers: {
           "Authorization": `Bearer ${token}`,
           "ngrok-skip-browser-warning": "69420"
         },
       });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos = jsonResponse;
         this.originalDatos = this.datos;
         return this.datos;
     }
    };

  async onChnageSucur(id:any){
   let data2 = await this.getRutas(id)
   let isSelectedAdded = false; 
   const options2 = data2.map((sucursal: any) => {
     if (!isSelectedAdded) {
       isSelectedAdded = true;
       return `
         <option selected disabled>--Seleccionar--</option>
         <option value="${sucursal.id}" data-id="${sucursal.id}">${sucursal.name}</option>
       `;
     } else {
       return `<option value="${sucursal.id}" data-id="${sucursal.id}">${sucursal.name}</option>`;
     }
   }).join('');
   const routesSelect = document.getElementById('routes') as HTMLSelectElement;
   routesSelect.innerHTML = options2;
  }
      
  datos_sucursal: any[] = [];
  async getSucursal(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "sucursal", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.datos_sucursal = jsonResponse;
         return this.datos_sucursal;
     }
   }

   async getRutas(id: any): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "routes/sucursal/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         return jsonResponse
     }
   }

  async AsingRutas(id:any){
  const token =  this.getCookie('_token');
  let data = await this.getSucursal()
  let isSelectedAdded = false; 
  const options_sucursal = data.map((sucursal: any) => {
    if (!isSelectedAdded) {
      isSelectedAdded = true;
      return `
        <option selected disabled>--Seleccionar--</option>
        <option value="${sucursal.id}" data-id="${sucursal.id}">${sucursal.name}</option>
      `;
    } else {
      return `<option value="${sucursal.id}" data-id="${sucursal.id}">${sucursal.name}</option>`;
    }
  }).join('');

    
    let htmlContent = `
    <h1>Reasignar cliente y ventas</h1>
    <div style="display: grid;">
        <label for="total_values">Sucursal</label>
          <select onchange="onChnageSucur(event)"  class="form-control input-form" id="sucursal">
          ${options_sucursal}
          </select>
        <label for="routes">Rutas</label>
        <select class="form-control input-form" id="routes">
            <option selected disabled>--Seleccionar--</option>
        </select>
    </div>`;


    Swal.fire({
      html: htmlContent,
      showConfirmButton: true,  // Mostrar el botón "OK"
      confirmButtonText: 'Guardar',  // Texto del botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,  
      showCloseButton: true,
      confirmButtonColor: '#0e658e',
      preConfirm: async () => {
        const routes = (document.getElementById('routes') as HTMLSelectElement).value
        try {
          if (routes == '' || routes == '--Seleccionar--') {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Llena el campo',
              showConfirmButton: false,
      
            });
            return;
          }else{
            const response = await fetch(
              this.url.Ms1 + 'cliente_ruta/update/' + id ,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({
                  route: routes
                }),
              })
              const data = await response.json();
              if(data.error){
                alert("error")
              }else{
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Reasignacion completa',
                  showConfirmButton: true, // Mostrar el botón "OK"
                  confirmButtonText: 'OK', // Texto del botón "OK"
                  allowOutsideClick: false, // Evitar el clic fuera del cuadro
                  allowEscapeKey: false, // Evitar la tecla "esc"
                  confirmButtonColor: '#0e658e',
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.reload();
                  }
                });
                            
            }
           
          }


        }
        catch (error) {
          console.error('Error:', error);
        }
      }
  });

  const sucursalSelect = document.getElementById('sucursal') as HTMLSelectElement;
  sucursalSelect.onchange = (event: Event) => this.onChnageSucur((event.target as HTMLSelectElement).value);
  }


  Create_loan(dato: any) {
    console.log(dato)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormLoansComponent);
    const componentRef: ComponentRef<FormLoansComponent> = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.selectOption(dato)
    componentRef.instance.name_Dis = true
    Swal.fire({
      title: 'Prestamos',
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    }) 
   

  }

     
  toggleRow(index: number): void {
    this.collapsedRows[index] = !this.collapsedRows[index];
  }
  collapsedRows: boolean[] = new Array(this.datos.length).fill(true);

  FormModalidad(id:string){
    const date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    const expiresStr = "expires=" + date.toUTCString();
    document.cookie = "_client" + "=" + id + ";" + expiresStr + ";path=/";
    this.router.navigate(['form-modalidad']);
  }
  
  triggerFileInput(input_id:any) {
    const fileInput = document.getElementById(input_id) as HTMLInputElement;
    const preview = document.getElementById('img-ad') as HTMLInputElement;
    fileInput.click();
    if(fileInput){
      fileInput.addEventListener('change', function() {
        const file = this.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            let img_base = e.target.result
            if (preview) {
              preview.src = img_base
            }
          };
          reader.readAsDataURL(file);
        }
        else {
          if (preview) {
            preview.innerHTML = 'No image selected';
          }
        }
      })
    }
 
  }
    
  async verFotos(dato: any): Promise<any> {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UpdateClientComponent);
      const componentRef: ComponentRef<UpdateClientComponent> = this.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.dato = dato;
   

      const swalContent = componentRef.location.nativeElement;
      const swal = this.url.showSwal(swalContent);
      this.url.setInitialSwal(swal);

      componentRef.instance.openImg = (title: string, input_id: string, imgs: string, type: string) => {
        if(!this.permissions.hasOwnProperty('btn_cliente_editar_imagen')){
          Swal.fire({
            title: title,
            html: `
              <img style="border-radius:10px; margin-top:20px; width: 250px; height: 250px;" src="${imgs}" id="img-ad" alt="Imagen 1">
              <input type="file" id="${input_id}" style="display: none;"/>
            `,
            showCloseButton: true,
            confirmButtonText: 'Guardar',
            confirmButtonColor: '#0e658e',
            onOpen: () => {
              let img = document.getElementById("img-ad");
              if (img) {
                img.addEventListener("click", this.triggerFileInput.bind(this, input_id));
              }
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              const token = this.getCookie("_token");
              const image_up = (document.getElementById(input_id) as HTMLInputElement).files;
              const formData = new FormData();
              if (image_up) {
                formData.append('img', image_up[0]);
              }
              formData.append('img_id', type);
              let dni:any;
              formData.append('customer_id', dato.id);

              const response = await fetch(this.url.Ms1 + "save_image", {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer ' + token,
                },
                body: formData
              });
              if (response.ok) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Modificado!',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  confirmButtonColor: '#0e658e'
                }).then(data => {
                  componentRef.instance.imagenList(dato.customer_detail_id.id);
                  const updatedContent = componentRef.location.nativeElement;
                  let infos = this.url.showSwal(updatedContent);
                  this.url.setInitialSwal(infos);
                });
              }
            }else{
              let infos = this.url.showSwal(swalContent);
              this.url.setInitialSwal(infos);
            }
          });
        }else{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'No tiene permisos editar imagenes',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
          });
          return
        }
      };
    }


  actualizar(dato: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FormClienteComponent);
    const componentRef: ComponentRef<FormClienteComponent> = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.dato = dato;
   
    Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,  // Mostrar el botón "OK"
      allowOutsideClick: false, // Evitar el clic fuera del cuadro
      allowEscapeKey: false,    // Evitar la tecla "esc"
      html:componentRef.location.nativeElement
    })
   

  }

  topeUpdate(id:any,limit:any){
    const token = this.getCookie("_token")
    Swal.fire({
      title: 'Ingrese el tope',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      inputValue: limit,
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      showLoaderOnConfirm: true,
      preConfirm: async (inputValue) =>{
        const data = {
          limit_loan: inputValue
        }
        const response = await fetch(this.url.Ms1 + "loan_mode/limit/" + id, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(data)
        });
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }


      }
    })
  }

  updateColor(id:any){
    const token = this.getCookie("_token")
    let  htmlContent = ""
    htmlContent += `
    <div>
      <label for="">Color Cliente</label>
      <select class="form-control input-form" name="color_id" id="color_id">
        <option value="naranja">Naranja</option>
        <option value="amarillo">Amarillo</option>
      </select>
    </div>
 `
    Swal.fire({
      title: "Cambiar color",
      html: htmlContent,
      showCloseButton: true,
      showConfirmButton: true,  
      confirmButtonText: 'Guardar',
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: '#0e658e',
    }).then(result =>{
      let color = (document.getElementById("color_id") as HTMLSelectElement).value
      const detalle_color = {
        user_id: id,
        color: color,
      };

      if(result.isConfirmed){
        fetch(this.url.Ms1 + "put_color", {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(detalle_color)
        }).then(result => result.json())
        .then(out => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Color actualizado',
            showConfirmButton: true,  // Mostrar el botón "OK"
            confirmButtonText: 'OK',  // Texto del botón "OK"
            allowOutsideClick: false, // Evitar el clic fuera del cuadro
            allowEscapeKey: false,    // Evitar la tecla "esc"
            confirmButtonColor: '#0e658e',
        });
        })
        
      }
    })


  }

  convertFecha(fecha: any){
    const dia = fecha.getDate();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear();
    return `${dia}/${mes}/${año}`;
  } 

  convertirFecha(fechaString: string): string {
    const fecha = new Date(fechaString);
    const year = fecha.getFullYear().toString().substr(-2); // Obtener los últimos dos dígitos del año
    const month = ('0' + (fecha.getMonth() + 1)).slice(-2); // Obtener el mes con dos dígitos
    const day = ('0' + fecha.getDate()).slice(-2); // Obtener el día con dos dígitos
    return `${year}/${month}/${day}`;
}

convertirFecha2(fechaString: string): string {
  const fecha = new Date(fechaString);
  const year = fecha.getFullYear().toString() // Obtener los últimos dos dígitos del año
  const month = ('0' + (fecha.getMonth() + 1)).slice(-2); // Obtener el mes con dos dígitos
  const day = ('0' + fecha.getDate()).slice(-2); // Obtener el día con dos dígitos
  return `${day}/${month}/${year}`;
}

  async VerHistorial(id: any){
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "get_loans_by_id/" + id, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420"
      },
    });
  let result = await response.json();
  
  let htmlconten = `
  <div>
  <table class="table table-responsive table-bordered table-hover" style="display: block;border-collapse:collapse;overflow-y:scroll; ">
      <thead>
          <th>Valor</th>
          <th style="min-width: 135px;">Fecha inicial</th>
          <th style="min-width: 135px;">Fecha final</th>
          <th style="min-width: 135px;">Ultimo pago</th>
          <th style="min-width: 135px;">Fecha de ultimo pago</th>
          <th style="min-width: 90px;">Dias en mora</th>
          <th>Estado</th>
          <th>GPS</th>
      </thead>
      <tbody>`;
      result.forEach((info:any, index:any) => {
        const fechaEntrada = new Date(info.start_date);
        const fechaSalida = new Date(info.expiry_date);
        const fecha1 = this.convertFecha(fechaEntrada)
        const fecha2 = this.convertFecha(fechaSalida)
        var colorTexto = info.state === "Inactivo" ? "red" : "green";
        const fechaConvertida = this.convertirFecha2(info.last_payment_date);

        htmlconten += `
         <tr>
            <td>$${this.formateaMiles(info.value)}</td>
            <td>${fecha1}</td>
            <td>${fecha2}</td>
            <td>${info.payment_value == 'None' ? '' : '$'+info.payment_value}</td>
            <td>${info.last_payment_date == 'None' ? '' : fechaConvertida }</td>
            <td>${info.loan_details_id.day_arrears}</td>
            <td style="color: ${colorTexto};">${info.state}</td>
            <td>
              <button id="gps-button-${index}" class="btn" style="color: #ffff;background-color: #0e658e;">Ver</button>
            </td>
        </tr>
       `;
      })
      htmlconten += `
      </tbody>
      </table>
      </div>`;


  Swal.fire({
    title: "Historial prestamos",
    html:htmlconten,
    confirmButtonText: 'OK',  // Texto del botón "OK"
    allowOutsideClick: false, // Evitar el clic fuera del cuadro
    allowEscapeKey: false,    // Evitar la tecla "esc"
    confirmButtonColor: '#0e658e',

  });
  result.forEach((info:any, index:any) => {
    const button = document.getElementById(`gps-button-${index}`);
    if (button) {
      button.addEventListener('click', () => this.GpsLoans(info));
    }
  })
  }

  GpsLoans(loans:any){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MapaLoanComponent);
    const componentRef: ComponentRef<MapaLoanComponent> = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.pagos = loans
    Swal.fire({
      title: 'GPS loans',
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      html:componentRef.location.nativeElement
    })
  }
     
  filterList(searchTerm: any): void {
    const info =  searchTerm?.target?.value?.toLowerCase() || '';
    const search_info = this.datos
    if (!info) {
      this.datos = [...this.originalDatos];
      this.filter_data = false;
    } else {
      this.filter_data = true;
      this.datos = this.originalDatos.filter(item =>
        (item.id.toString().includes(info) ||
        item.name.toLowerCase().includes(info))
      );
    }
  }

}
