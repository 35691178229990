
<div class="menu-container" style="position: relative;">


  <app-menu-super-user></app-menu-super-user>
  
  <div style="text-align: -webkit-center; font-size: 16px;">
    <div>
      <label for="">Fecha Inicial</label>
      <div>
        <input placeholder="Fecha Inicial" type="text" style="text-align: center;" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedDates" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  >
      </div>
    </div>
    <div>
      
      <label for="">Fecha final</label>
      <input placeholder="Fecha Final" type="text" style="text-align: center;" id="date_initial" name="date_initial" class="form-control input-form" bsDatepicker [(ngModel)]="selectedFinal" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" >
    </div>
    <div style="margin: 10px;">
      <i class="fa-solid fa-magnifying-glass"  style="font-size: 21px;" (click)="search()"></i>
    </div>
  </div>


  <div class="list-sucursa" >
    <div *ngFor="let key of getKeys(nominas)">
      <h2 style="text-align: center;">{{ key }}</h2>

      <div *ngFor="let dato of nominas[key]"  class="contaner-princi">
        <div class="contaner-list" >
          <div class="contener-datas" >
            <div style="width: 100%; text-align: start;">
              <div style="text-align: center; border-bottom: solid 2px #adb5bd59;width: 95%;margin: auto; height: 35px; overflow-x:scroll; white-space:nowrap;">
                <label for="" class="info-client" >{{dato.name}}</label>
              </div>
              <div style="display: block; overflow: hidden;overflow-x: auto;">
                <div class="row" style="text-align: center; display: table-cell;">
                  <div style="border-right: solid 2px #adb5bd59; height: 24px; white-space: nowrap;">
                    <p style="font-size: 15px;"> Fecha de pago</p>
                  </div>
                  <div style="border-right: solid 2px #adb5bd59; height: 55px;">
                    <span style="font-size: 16px; font-weight: 600; display: block;">{{formatDate(dato.date_payment)}}</span>
                    <span style="font-size: 16px; font-weight: 600; display: block;">{{dato.payment_type}}</span>
                  </div>
                </div>
                <div class="row" style="text-align: center; display: table-cell;">
                  <div style="border-right: solid 2px #adb5bd59; height: 24px; white-space: nowrap;">
                    <p style="font-size: 15px;"> Valor pagado</p>
                  </div>
                  <div style="border-right: solid 2px #adb5bd59; height: 55px;">
                    <span style="font-size: 16px; font-weight: 600; display: block;">${{dato.payment| number:'1.2-2'}}</span>
                  </div>
                </div>

                <div class="row" style="text-align: center; display: table-cell;">
                  <div style="border-right: solid 2px #adb5bd59; height: 24px; white-space: nowrap;">
                    <p style="font-size: 15px;"> Tipo de Pago</p>
                  </div>
                  <div style="border-right: solid 2px #adb5bd59; height: 55px;">
                    <span *ngIf="dato.transfer == true" style="font-size: 16px; font-weight: 600; display: block;">Transferencia (Sucursal)</span>
                    <span *ngIf="dato.transfer != true" style="font-size: 16px; font-weight: 600; display: block;">Efectivo (Ruta)</span>
                  </div>
                </div>
                <div class="row" style="text-align: center; display: table-cell;">
                  <div style="border-right: solid 2px #adb5bd59; height: 24px; white-space: nowrap;">
                    <p style="font-size: 15px;">Pagado a:</p>
                  </div>
                  <div style="border-right: solid 2px #adb5bd59; height: 55px;">
                    <span style="font-size: 16px; font-weight: 600; display: block;">{{dato.user}}</span>
                  </div>
                
                </div>


              </div>


            </div>
          </div>
        </div>
      </div>


    </div>
    
  
  </div>
</div>
