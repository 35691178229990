import { Component, AfterViewInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Url, Permission } from '../service/data.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-festivos',
  templateUrl: './form-festivos.component.html',
  styleUrls: ['./form-festivos.component.css']
})

export class FormFestivosComponent{
  dayFestive: string | null = null;
  selectedDatesArray: string[] = [];
  selectedDate: string = ''; // Fecha seleccionada
  name_festivo: any;
  selectedDates: string[] = []; // Array para almacenar fechas seleccionadas
  listFestivo: boolean = false;
  festivos: any = [];
  permissions: any = {}

  monthNames = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  datesByMonth: { [key: string]: string[] } = {};

  constructor(public url: Url, private routers: Router,) {
  }

  
  ngOnInit() {
    this.getFestivos();
    this.permissions = Permission.ElementPermissionObjects()
  }

  async getFestivos(): Promise<any> {
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "festivos", {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         this.festivos = jsonResponse;
         return this.festivos;
     }
   }

  async SelectOptionFestivo(event:any){
    const id_festivo = event.target.value;
    let info = await this.getIdFestivo(id_festivo)
    const elemento =  event.target.selectedOptions[0];
    const name = elemento.getAttribute('data-name');
    this.name_festivo = name 
    this.selectedDates = info.select_holiday.split(',')
   }


   async getIdFestivo(id:any){
    const token = this.getCookie("_token")
    const response = await fetch(this.url.Ms1 + "festivos/" + id, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + token,
         "ngrok-skip-browser-warning": "69420"
       },
     });
       if (response.ok) {
         const jsonResponse = await response.json();
         return jsonResponse
     }
   }

  getCookie(name:string) {
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if(cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    return null;
  }
  
  updateFestivo(){
    if(!this.listFestivo){
      (document.getElementById("swal2-title") as HTMLElement).textContent = "Editar Festivos"
      this.listFestivo = true
    }
    else{
      (document.getElementById("swal2-title") as HTMLElement).textContent = "Crear Festivos"
      this.name_festivo = ''
      this.selectedDates = []
      this.listFestivo = false
    }
  }

  async PostFestivoUpdates(){
    let id_festivo = (document.getElementById("list_concepts") as HTMLSelectElement).value
    let name_festivos = (document.getElementById("name_festivo") as HTMLInputElement).value
    const token = this.getCookie("_token")
    this.selectedDates.forEach(date => {
      const parts = date.split("-");
      const monthIndex = parseInt(parts[1]) - 1;
      const monthName = this.monthNames[monthIndex];
    });

  

    const select_holiday = String(this.selectedDates)
    if (select_holiday == ""){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,
      });
      return;
    }

    const response = await fetch(this.url.Ms1 + "festivos", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(
        {
          name_festivos: name_festivos,
          id_holidays : id_festivo,
          holidays: select_holiday,
        })
    });
    if(response.ok){
        Swal.fire({
          icon: 'success',
          title: 'Festivo modificado',
          showConfirmButton: true,  // Mostrar el botón "OK"
          confirmButtonText: 'OK',  // Texto del botón "OK"
          allowOutsideClick: false, // Evitar el clic fuera del cuadro
          allowEscapeKey: false,    // Evitar la tecla "esc"
          confirmButtonColor: '#0e658e',
        })

    }
  }

  onSelectedDateChange(): void {
    if (this.selectedDate && !this.selectedDates.includes(this.selectedDate)) {
      const formattedDate = formatDate(this.selectedDate, 'dd-MM', 'en-US');
      this.selectedDates.push(formattedDate);
      this.selectedDate = ''; // Limpiar el campo de fecha después de agregarlo
    }
  }

  removeSelectedDate(index: number): void {
    this.selectedDates.splice(index, 1);
  }

  addToSelectedDatesArray(formattedDate: string) {
    this.selectedDatesArray.push(formattedDate);
    this.dayFestive = this.selectedDatesArray.join(', ');
  }  


  async postFestivos(){

    let name_festivo = (document.querySelector('#name_festivo') as HTMLInputElement).value
    let selectedDate = (document.querySelector('#selectedDate') as HTMLInputElement).value
    if(selectedDate == '' || name_festivo == '' || String(this.selectedDates) == ''){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Llena todos los campos...',
        showConfirmButton: false,
      });
      return;
    }

    const token = this.getCookie("_token")
    this.datesByMonth = {}; // Reinicializar el objeto al comienzo
    this.selectedDates.forEach(date => {
      const parts = date.split("-");
      const monthIndex = parseInt(parts[1]) - 1;
      const monthName = this.monthNames[monthIndex];
    });


    const select_holiday = String(this.selectedDates)
    const name = String(this.name_festivo)
      try {
        const response = await fetch(this.url.Ms1 + "festivos", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(
            {
              select_holiday:select_holiday,
              name: name
            })
        });

        if (response.ok) {

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Enviado!',
            text: 'Festivos Creado',
            showConfirmButton: true,  // Mostrar el botón "OK"
              confirmButtonText: 'OK',  // Texto del botón "OK"
              allowOutsideClick: false, // Evitar el clic fuera del cuadro
              allowEscapeKey: false,    // Evitar la tecla "esc"
              confirmButtonColor: '#0e658e',
          }).then((result) => {
            if (result.isConfirmed) {
              this.routers.navigate(['/super-usuario']);   
            }
          })
        }

      } catch (error) {
        console.error('Error al enviar el formulario', error);
      }

  
  }

}  


