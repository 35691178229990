import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-routing-machine';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css'],
})
export class MapaComponent  implements OnInit {

  pagos:any = []
  private map!: L.Map;
  constructor() { }

  ngOnInit() {
    this.initMap();
  }
  // private initMap(): void {
  //   this.map = L.map('map').setView([4.816896, -75.7006336], 15);

  //   // Añadir un tile layer de OpenStreetMap
  //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //     maxZoom: 19,
  //     attribution: '© Genion.com.co'
  //   }).addTo(this.map);

  //   const iconDefault = L.icon({
  //     iconUrl: 'assets/images/marker-icon.png',
  //     shadowUrl: 'assets/images/marker-shadow.png',
  //     iconSize: [25, 41],
  //     iconAnchor: [12, 41],
  //     popupAnchor: [1, -34],
  //     shadowSize: [41, 41]
  //   });
  //   L.Marker.prototype.options.icon = iconDefault;

  //   const locationMap = new Map<string, any[]>();
  //   this.pagos.forEach((pago:any, index:any) => {
  //     if (pago.gps_payment_id !== null && pago.gps_payment_id !== undefined) {
  //       const lat = parseFloat(pago.gps_payment_id.latitude);
  //       const lon = parseFloat(pago.gps_payment_id.longitude);
  //       const key = `${lat},${lon}`; // Usar latitud y longitud como clave
  //       const date = new Date(pago.date).toLocaleDateString();
  //       const popupText = `<b>#${index + 1} Pago de $${pago.fee_payment}</b><br>Fecha de pago: ${date}<br><b>#${index + 1} Pago de $${pago.fee_payment}</b><br>Fecha de pago: ${date}`;
    
  //       if (!locationMap.has(key)) {
  //         locationMap.set(key, []);
  //       }
    
  //       locationMap.get(key)!.push({ lat, lon, popupText });
  //     }
  //   });
  //   locationMap.forEach((locations, key) => {
  //     locations.forEach((location, i) => {
  //       const latLng = L.latLng(location.lat ,location.lon);
  //       L.marker(latLng, { draggable: false })
  //         .bindPopup(location.popupText)
  //         .addTo(this.map);
  //     });
  //   });
  // }
  
  private initMap(): void {
    const map = L.map('map').setView([4.816896, -75.7006336], 15);

    // Añadir un tile layer de OpenStreetMap
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© Plataformal'
    }).addTo(map);


    const iconDefault = L.icon({
      iconUrl: 'assets/images/marker-icon.png',  // Ruta actualizada
      shadowUrl: 'assets/images/marker-shadow.png',  // Ruta actualizada
      iconSize: [25, 41],  // Tamaño del ícono
      iconAnchor: [12, 41], // Punto de anclaje
      popupAnchor: [1, -34], // Punto desde el cual se alinea el popup
      shadowSize: [41, 41]  // Tamaño de la sombra
    });
    L.Marker.prototype.options.icon = iconDefault;

    const waypoints = this.pagos
      .filter((pago:any) => pago.gps_payment_id !== null)
      .map((pago:any, index:any) => {
        const lat = parseFloat(pago.gps_payment_id.latitude);
        const lon = parseFloat(pago.gps_payment_id.longitude);
        const date = new Date(pago.date).toLocaleDateString();  // Formato de fecha
        const popupText = `<b>Fecha de pago: ${date}</b><br>Valor de $${pago.fee_payment}`;
        
        const marker = L.marker([lat, lon], { draggable: false }).bindPopup(popupText);
        marker.addTo(map);
        
        // Devolver la latitud y longitud como waypoints
        return L.latLng(lat, lon);
      });

   
      const routingPlan = L.Routing.plan(waypoints, {
        createMarker: (i, wp) => {
          const pago = this.pagos[i]; // Obtener el pago actual
          const date = new Date(pago.date).toLocaleDateString();  // Formato de fecha
          const popupText = `<b> Fecha de pago: ${date}</b><br>Valor de $${pago.fee_payment}`;
  
          return L.marker(wp.latLng, {
            icon: iconDefault,  // Usar el icono personalizado
            draggable: false    // Evitar que el marcador sea movido
          }).bindPopup(popupText);  // Usar el texto personalizado del pago
        }
      });
      
      L.Routing.control({
        plan: routingPlan,
        routeWhileDragging: false,
        show: false
      }).addTo(map);

  }
  
}
